import * as globals from "./globals";
import type { OctopusTheme } from "./OctopusTheme";
export const darkTheme: OctopusTheme = {
    "colorCalloutTextInfo": globals["colorScales"]["blue"]["300"],
    "colorCalloutTextSuccess": globals["colorScales"]["green"]["300"],
    "colorCalloutTextWarning": globals["colorScales"]["yellow"]["200"],
    "colorCalloutTextDanger": globals["colorScales"]["red"]["300"],
    "colorCalloutTextFeature": globals["colorScales"]["purple"]["300"],
    "colorCalloutTextDefault": globals["colorScales"]["navy"]["300"],
    "colorCalloutTextBody": globals["colorScales"]["white"],
    "colorCalloutIconInfo": globals["colorScales"]["blue"]["300"],
    "colorCalloutIconSuccess": globals["colorScales"]["green"]["300"],
    "colorCalloutIconWarning": globals["colorScales"]["yellow"]["200"],
    "colorCalloutIconDanger": globals["colorScales"]["red"]["300"],
    "colorCalloutIconFeature": globals["colorScales"]["purple"]["300"],
    "colorCalloutIconDefault": globals["colorScales"]["navy"]["300"],
    "colorCalloutBorderInfo": globals["colorScales"]["blue"]["400"],
    "colorCalloutBorderSuccess": globals["colorScales"]["green"]["400"],
    "colorCalloutBorderWarning": globals["colorScales"]["yellow"]["300"],
    "colorCalloutBorderDanger": globals["colorScales"]["red"]["400"],
    "colorCalloutBorderFeature": globals["colorScales"]["purple"]["400"],
    "colorCalloutBorderDefault": globals["colorScales"]["navy"]["400"],
    "colorCalloutBackgroundInfoDefault": "rgba(21, 94, 160, 0.64)",
    "colorCalloutBackgroundInfoHover": "rgba(21, 94, 160, 0.48)",
    "colorCalloutBackgroundInfoPressed": "rgba(21, 94, 160, 0.32)",
    "colorCalloutBackgroundSuccessDefault": "rgba(0, 106, 61, 0.64)",
    "colorCalloutBackgroundSuccessHover": "rgba(0, 106, 61, 0.48)",
    "colorCalloutBackgroundSuccessPressed": "rgba(0, 106, 61, 0.32)",
    "colorCalloutBackgroundAttentionDefault": "rgba(146, 112, 2, 0.72)",
    "colorCalloutBackgroundAttentionHover": "rgba(146, 112, 2, 0.56)",
    "colorCalloutBackgroundAttentionPressed": "rgba(146, 112, 2, 0.40)",
    "colorCalloutBackgroundDangerDefault": "rgba(170, 48, 48, 0.64)",
    "colorCalloutBackgroundDangerHover": "rgba(170, 48, 48, 0.48)",
    "colorCalloutBackgroundDangerPressed": "rgba(170, 48, 48, 0.32)",
    "colorCalloutBackgroundFeatureDefault": "rgba(112, 67, 183, 0.64)",
    "colorCalloutBackgroundFeatureHover": "rgba(112, 67, 183, 0.48)",
    "colorCalloutBackgroundFeaturePressed": "rgba(112, 67, 183, 0.32)",
    "colorCalloutBackgroundDefaultDefault": "rgba(62, 96, 125, 0.64)",
    "colorCalloutBackgroundDefaultHover": "rgba(62, 96, 125, 0.48)",
    "colorCalloutBackgroundDefaultPressed": "rgba(62, 96, 125, 0.32)",
    "colorMenuListTextPrimary": globals["colorScales"]["navy"]["100"],
    "colorMenuListTextSecondary": globals["colorScales"]["navy"]["200"],
    "colorMenuListTextHighlight": globals["colorScales"]["blue"]["400"],
    "colorMenuListBackgroundDefault": globals["colorScales"]["navy"]["800"],
    "colorMenuListBackgroundHover": globals["colorScales"]["navy"]["700"],
    "colorMenuListIconPrimary": globals["colorScales"]["navy"]["400"],
    "colorTextFieldTextPrimary": globals["colorScales"]["navy"]["100"],
    "colorTextFieldTextMuted": globals["colorScales"]["navy"]["300"],
    "colorTextFieldTextSelected": globals["colorScales"]["blue"]["400"],
    "colorTextFieldTextDanger": globals["colorScales"]["red"]["400"],
    "colorTextFieldTextDisabled": globals["colorScales"]["navy"]["600"],
    "colorTextFieldBorderDefault": globals["colorScales"]["navy"]["600"],
    "colorTextFieldBorderHover": globals["colorScales"]["navy"]["300"],
    "colorTextFieldBorderActive": globals["colorScales"]["blue"]["400"],
    "colorTextFieldBorderDanger": globals["colorScales"]["red"]["400"],
    "colorTextFieldBorderDisabled": globals["colorScales"]["navy"]["600"],
    "colorTextFieldIconPrimary": globals["colorScales"]["navy"]["200"],
    "colorTextFieldIconDisabled": globals["colorScales"]["navy"]["600"],
    "colorChipFilledTextPrimary": globals["colorScales"]["navy"]["200"],
    "colorChipFilledTextSecondary": globals["colorScales"]["purple"]["200"],
    "colorChipFilledTextInfo": globals["colorScales"]["blue"]["200"],
    "colorChipFilledTextSuccess": globals["colorScales"]["green"]["200"],
    "colorChipFilledTextWarning": globals["colorScales"]["yellow"]["200"],
    "colorChipFilledTextDanger": globals["colorScales"]["red"]["200"],
    "colorChipFilledBackgroundPrimary": globals["colorScales"]["navy"]["700"],
    "colorChipFilledBackgroundSecondary": globals["colorScales"]["purple"]["700"],
    "colorChipFilledBackgroundInfo": globals["colorScales"]["blue"]["700"],
    "colorChipFilledBackgroundSuccess": globals["colorScales"]["green"]["700"],
    "colorChipFilledBackgroundWarning": globals["colorScales"]["yellow"]["700"],
    "colorChipFilledBackgroundDanger": globals["colorScales"]["red"]["700"],
    "colorChipFilledIconPrimary": globals["colorScales"]["navy"]["200"],
    "colorChipFilledIconSecondary": globals["colorScales"]["purple"]["200"],
    "colorChipFilledIconInfo": globals["colorScales"]["blue"]["200"],
    "colorChipFilledIconSuccess": globals["colorScales"]["green"]["200"],
    "colorChipFilledIconWarning": globals["colorScales"]["yellow"]["200"],
    "colorChipFilledIconDanger": globals["colorScales"]["red"]["200"],
    "colorChipOutlinedBorderPrimary": globals["colorScales"]["navy"]["400"],
    "colorChipOutlinedBorderSecondary": globals["colorScales"]["purple"]["400"],
    "colorChipOutlinedBorderInfo": globals["colorScales"]["blue"]["400"],
    "colorChipOutlinedBorderSuccess": globals["colorScales"]["green"]["400"],
    "colorChipOutlinedBorderWarning": globals["colorScales"]["yellow"]["400"],
    "colorChipOutlinedBorderDanger": globals["colorScales"]["red"]["400"],
    "colorChipOutlinedTextPrimary": globals["colorScales"]["navy"]["400"],
    "colorChipOutlinedTextSecondary": globals["colorScales"]["purple"]["400"],
    "colorChipOutlinedTextInfo": globals["colorScales"]["blue"]["400"],
    "colorChipOutlinedTextSuccess": globals["colorScales"]["green"]["400"],
    "colorChipOutlinedTextWarning": globals["colorScales"]["yellow"]["400"],
    "colorChipOutlinedTextDanger": globals["colorScales"]["red"]["400"],
    "colorChipOutlinedIconPrimary": globals["colorScales"]["navy"]["400"],
    "colorChipOutlinedIconSecondary": globals["colorScales"]["purple"]["400"],
    "colorChipOutlinedIconInfo": globals["colorScales"]["blue"]["400"],
    "colorChipOutlinedIconSuccess": globals["colorScales"]["green"]["400"],
    "colorChipOutlinedIconWarning": globals["colorScales"]["yellow"]["400"],
    "colorChipOutlinedIconDanger": globals["colorScales"]["red"]["400"],
    "colorButtonBackgroundPrimaryDefault": globals["colorScales"]["green"]["500"],
    "colorButtonBackgroundPrimaryHover": globals["colorScales"]["green"]["600"],
    "colorButtonBackgroundPrimaryPressed": globals["colorScales"]["green"]["700"],
    "colorButtonBackgroundSecondaryDefault": "rgba(26, 119, 202, 0.16)",
    "colorButtonBackgroundSecondaryHover": "rgba(26, 119, 202, 0.24)",
    "colorButtonBackgroundSecondaryPressed": "rgba(26, 119, 202, 0.32)",
    "colorButtonBackgroundTertiaryDefault": "rgba(26, 119, 202, 0.0)",
    "colorButtonBackgroundTertiaryHover": "rgba(26, 119, 202, 0.16)",
    "colorButtonBackgroundTertiaryPressed": "rgba(26, 119, 202, 0.32)",
    "colorButtonBackgroundDestructiveDefault": globals["colorScales"]["red"]["500"],
    "colorButtonBackgroundDestructiveHover": globals["colorScales"]["red"]["600"],
    "colorButtonBackgroundDestructivePressed": globals["colorScales"]["red"]["700"],
    "colorButtonBackgroundQuietDefault": "rgba(26, 119, 202, 0.0)",
    "colorButtonBackgroundQuietHover": "rgba(26, 119, 202, 0.16)",
    "colorButtonBackgroundQuietPressed": "rgba(26, 119, 202, 0.32)",
    "colorButtonBackgroundLoudDefault": globals["colorScales"]["blue"]["500"],
    "colorButtonBackgroundLoudHover": globals["colorScales"]["blue"]["600"],
    "colorButtonBackgroundLoudPressed": globals["colorScales"]["blue"]["700"],
    "colorButtonBackgroundNeutralDefault": globals["colorScales"]["navy"]["900"],
    "colorButtonBackgroundNeutralHover": globals["colorScales"]["navy"]["800"],
    "colorButtonBackgroundNeutralPressed": globals["colorScales"]["navy"]["700"],
    "colorButtonBackgroundDisabled": globals["colorScales"]["navy"]["800"],
    "colorButtonTextPrimary": globals["colorScales"]["white"],
    "colorButtonTextSecondary": globals["colorScales"]["blue"]["400"],
    "colorButtonTextTertiary": globals["colorScales"]["blue"]["400"],
    "colorButtonTextDestructive": globals["colorScales"]["white"],
    "colorButtonTextQuiet": globals["colorScales"]["blue"]["400"],
    "colorButtonTextLoud": globals["colorScales"]["white"],
    "colorButtonTextNeutral": globals["colorScales"]["navy"]["100"],
    "colorButtonTextDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonBorderSecondary": globals["colorScales"]["blue"]["500"],
    "colorButtonBorderDisabled": globals["colorScales"]["navy"]["800"],
    "colorButtonIconPrimary": globals["colorScales"]["white"],
    "colorButtonIconSecondary": globals["colorScales"]["blue"]["400"],
    "colorButtonIconTertiary": globals["colorScales"]["blue"]["400"],
    "colorButtonIconDestructive": globals["colorScales"]["white"],
    "colorButtonIconQuiet": globals["colorScales"]["blue"]["400"],
    "colorButtonIconLoud": globals["colorScales"]["white"],
    "colorButtonIconNeutral": globals["colorScales"]["navy"]["100"],
    "colorButtonIconDisabled": globals["colorScales"]["navy"]["600"],
    "colorDialogBackgroundPrimary": globals["colorScales"]["grey"]["800"],
    "colorDialogHelpPanelText": globals["colorScales"]["white"],
    "colorProgressBackgroundPrimary": globals["colorScales"]["blue"]["300"],
    "colorProgressBackgroundSecondary": globals["colorScales"]["blue"]["600"],
    "colorProgressTextInfo": globals["colorScales"]["blue"]["400"],
    "colorProgressBarForegroundLinear": globals["colorScales"]["blue"]["600"],
    "colorProgressBarForegroundCircular": globals["colorScales"]["blue"]["500"],
    "colorProgressBarBackgroundLinear": globals["colorScales"]["blue"]["300"],
    "colorProgressBarTextPrimary": globals["colorScales"]["white"],
    "colorNavigationBackgroundPrimary": globals["colorScales"]["navy"]["900"],
    "colorNavigationBackgroundSecondary": globals["colorScales"]["navy"]["100"],
    "colorNavigationButtonBackgroundPrimaryDefault": globals["colorScales"]["navy"]["800"],
    "colorNavigationButtonBackgroundPrimaryHover": globals["colorScales"]["blue"]["500"],
    "colorNavigationButtonBackgroundPrimaryPressed": globals["colorScales"]["blue"]["500"],
    "colorNavigationButtonTextPrimary": globals["colorScales"]["white"],
    "colorNavigationButtonIconPrimary": globals["colorScales"]["white"],
    "colorNavigationLinkBackgroundPrimaryHover": globals["colorScales"]["blue"]["500"],
    "colorNavigationLinkBackgroundPrimaryPressed": globals["colorScales"]["blue"]["600"],
    "colorNavigationLinkBackgroundPrimaryActive": globals["colorScales"]["blue"]["500"],
    "colorNavigationLinkTextPrimary": globals["colorScales"]["white"],
    "colorNavigationLinkIconPrimary": globals["colorScales"]["white"],
    "colorPaginationBackgroundDefault": globals["colorScales"]["navy"]["900"],
    "colorPaginationBackgroundHover": globals["colorScales"]["navy"]["500"],
    "colorPaginationBackgroundActive": globals["colorScales"]["blue"]["400"],
    "colorPaginationBackgroundDisabled": globals["colorScales"]["navy"]["900"],
    "colorPaginationTextDefault": globals["colorScales"]["navy"]["100"],
    "colorPaginationTextHover": globals["colorScales"]["white"],
    "colorPaginationTextActive": globals["colorScales"]["navy"]["900"],
    "colorPaginationTextDisabled": globals["colorScales"]["navy"]["600"],
    "colorPaginationIconDefault": globals["colorScales"]["navy"]["100"],
    "colorPaginationIconHover": globals["colorScales"]["white"],
    "colorPaginationIconActive": globals["colorScales"]["navy"]["900"],
    "colorPaginationIconDisabled": globals["colorScales"]["navy"]["600"],
    "colorToggleIconDefault": globals["colorScales"]["navy"]["300"],
    "colorToggleIconActive": globals["colorScales"]["blue"]["400"],
    "colorToggleIconDisabled": globals["colorScales"]["navy"]["500"],
    "colorToggleBackgroundOn": globals["colorScales"]["blue"]["200"],
    "colorToggleBackgroundOff": globals["colorScales"]["navy"]["500"],
    "colorToggleBackgroundDisabled": globals["colorScales"]["navy"]["700"],
    "colorToggleForegroundOn": globals["colorScales"]["blue"]["400"],
    "colorToggleForegroundOff": globals["colorScales"]["navy"]["300"],
    "colorToggleForegroundDisabled": globals["colorScales"]["navy"]["600"],
    "colorToggleOverlayOn": "rgba(205, 228, 247, 0.16)",
    "colorToggleOverlayOff": "rgba(85, 121, 153, 0.16)",
    "colorTextPrimary": globals["colorScales"]["navy"]["100"],
    "colorTextMuted": globals["colorScales"]["navy"]["300"],
    "colorTextSubtle": globals["colorScales"]["navy"]["400"],
    "colorTextDisabled": globals["colorScales"]["navy"]["600"],
    "colorTextInverse": globals["colorScales"]["navy"]["900"],
    "colorTextSelected": globals["colorScales"]["blue"]["400"],
    "colorTextInfo": globals["colorScales"]["blue"]["300"],
    "colorTextSuccess": globals["colorScales"]["green"]["300"],
    "colorTextWarning": globals["colorScales"]["yellow"]["200"],
    "colorTextDanger": globals["colorScales"]["red"]["300"],
    "colorTextFeature": globals["colorScales"]["purple"]["300"],
    "colorTextLinkDefault": globals["colorScales"]["blue"]["300"],
    "colorTextLinkPressed": globals["colorScales"]["blue"]["400"],
    "colorIconPrimary": globals["colorScales"]["navy"]["100"],
    "colorIconMuted": globals["colorScales"]["navy"]["300"],
    "colorIconSubtle": globals["colorScales"]["navy"]["400"],
    "colorIconDisabled": globals["colorScales"]["navy"]["600"],
    "colorIconInverse": globals["colorScales"]["navy"]["900"],
    "colorIconSelected": globals["colorScales"]["blue"]["400"],
    "colorIconInfo": globals["colorScales"]["blue"]["300"],
    "colorIconSuccess": globals["colorScales"]["green"]["300"],
    "colorIconWarning": globals["colorScales"]["yellow"]["200"],
    "colorIconDanger": globals["colorScales"]["red"]["300"],
    "colorIconFeature": globals["colorScales"]["purple"]["300"],
    "colorIconLinkDefault": globals["colorScales"]["blue"]["300"],
    "colorIconLinkPressed": globals["colorScales"]["blue"]["400"],
    "colorBorderPrimary": globals["colorScales"]["navy"]["700"],
    "colorBorderMuted": globals["colorScales"]["navy"]["900"],
    "colorBorderSubtle": globals["colorScales"]["navy"]["600"],
    "colorBorderDisabled": globals["colorScales"]["navy"]["600"],
    "colorBorderInverse": globals["colorScales"]["navy"]["900"],
    "colorBorderSelected": globals["colorScales"]["blue"]["400"],
    "colorBorderInfo": globals["colorScales"]["blue"]["400"],
    "colorBorderSuccess": globals["colorScales"]["green"]["400"],
    "colorBorderWarning": globals["colorScales"]["yellow"]["300"],
    "colorBorderDanger": globals["colorScales"]["red"]["400"],
    "colorBorderFeature": globals["colorScales"]["purple"]["400"],
    "colorBorderInput": globals["colorScales"]["navy"]["600"],
    "colorBorderLinkDefault": globals["colorScales"]["blue"]["300"],
    "colorBorderLinkPressed": globals["colorScales"]["blue"]["400"],
    "colorBackgroundPrimaryDefault": globals["colorScales"]["navy"]["800"],
    "colorBackgroundPrimaryHovered": globals["colorScales"]["navy"]["700"],
    "colorBackgroundPrimaryPressed": globals["colorScales"]["navy"]["900"],
    "colorBackgroundSecondaryDefault": globals["colorScales"]["navy"]["900"],
    "colorBackgroundSecondaryHovered": globals["colorScales"]["navy"]["800"],
    "colorBackgroundSecondaryPressed": globals["colorScales"]["navy"]["700"],
    "colorBackgroundSubtle": globals["colorScales"]["navy"]["700"],
    "colorBackgroundDisabled": globals["colorScales"]["navy"]["700"],
    "colorBackgroundContrast": globals["colorScales"]["black"],
    "colorBackgroundInversePrimary": globals["colorScales"]["white"],
    "colorBackgroundInverseSecondary": globals["colorScales"]["navy"]["200"],
    "colorBackgroundInverseNeutral": globals["colorScales"]["slate"]["400"],
    "colorBackgroundInverseSuccess": globals["colorScales"]["green"]["400"],
    "colorBackgroundSelected": globals["colorScales"]["blue"]["400"],
    "colorBackgroundInfo": globals["colorScales"]["blue"]["700"],
    "colorBackgroundSuccess": globals["colorScales"]["green"]["700"],
    "colorBackgroundWarning": globals["colorScales"]["yellow"]["600"],
    "colorBackgroundDanger": globals["colorScales"]["red"]["700"],
    "colorBackgroundFeature": globals["colorScales"]["purple"]["700"],
    "colorOverlayBackgroundPrimary": "rgba(17, 26, 35, 0.56)",
    "colorOverlayBackgroundMuted": "rgba(17, 26, 35, 0.24)",
    "colorActionButtonBorderSecondary": globals["colorScales"]["blue"]["500"],
    "colorActionButtonBorderDisabled": globals["colorScales"]["navy"]["600"],
    "colorActionButtonTextPrimary": globals["colorScales"]["white"],
    "colorActionButtonTextSecondary": globals["colorScales"]["blue"]["400"],
    "colorActionButtonTextTernary": globals["colorScales"]["blue"]["300"],
    "colorActionButtonTextDisabled": globals["colorScales"]["navy"]["500"],
    "colorActionButtonIconPrimary": globals["colorScales"]["white"],
    "colorActionButtonIconSecondary": globals["colorScales"]["blue"]["400"],
    "colorActionButtonIconTernary": globals["colorScales"]["blue"]["300"],
    "colorActionButtonIconDisabled": globals["colorScales"]["navy"]["500"],
    "colorActionButtonBackgroundDisabled": globals["colorScales"]["navy"]["700"],
    "colorActionButtonBackgroundPrimaryDefault": globals["colorScales"]["green"]["500"],
    "colorActionButtonBackgroundPrimaryHover": globals["colorScales"]["green"]["600"],
    "colorActionButtonBackgroundPrimaryPressed": globals["colorScales"]["green"]["700"],
    "colorActionButtonBackgroundSecondaryDefault": globals["colorScales"]["blue"]["900"],
    "colorActionButtonBackgroundSecondaryHover": "rgba(68, 155, 225, 0.08)",
    "colorActionButtonBackgroundSecondaryPressed": "rgba(68, 155, 225, 0.24)",
    "colorActionButtonBackgroundTernaryDefault": "rgba(17, 26, 35, 0.0)",
    "colorActionButtonBackgroundTernaryHover": "rgba(68, 155, 225, 0.08)",
    "colorActionButtonBackgroundTernaryPressed": "rgba(26, 119, 202, 0.24)",
    "colorActionButtonBackgroundCreateReleaseDefault": globals["colorScales"]["blue"]["500"],
    "colorActionButtonBackgroundCreateReleaseHover": globals["colorScales"]["blue"]["600"],
    "colorActionButtonBackgroundCreateReleasePressed": globals["colorScales"]["blue"]["600"],
    "colorCardBackgroundDefault": globals["colorScales"]["navy"]["700"],
    "colorCardBackgroundHover": globals["colorScales"]["navy"]["600"],
    "colorCarouselTextPrimary": globals["colorScales"]["white"],
    "colorCarouselTextMuted": globals["colorScales"]["navy"]["200"],
    "colorCarouselIconDefault": "rgba(169, 187, 203, 0.32)",
    "colorCarouselIconHover": "rgba(169, 187, 203, 0.72)",
    "colorCarouselIconSelected": globals["colorScales"]["blue"]["400"],
    "colorCarouselIconActive": "rgba(169, 187, 203, 0.72)",
    "colorCarouselBackgroundPrimary": globals["colorScales"]["navy"]["900"],
    "colorNavListTextDefault": globals["colorScales"]["navy"]["100"],
    "colorNavListTextHover": globals["colorScales"]["white"],
    "colorNavListTextActive": globals["colorScales"]["blue"]["400"],
    "colorNavListIconDefault": globals["colorScales"]["navy"]["500"],
    "colorNavListIconHover": globals["colorScales"]["white"],
    "colorNavListIconActive": globals["colorScales"]["blue"]["400"],
    "colorNavListBackgroundDefault": "rgba(26, 119, 202, 0.0)",
    "colorNavListBackgroundHover": globals["colorScales"]["blue"]["500"],
    "colorNavListBackgroundActive": globals["colorScales"]["navy"]["800"],
    "colorPopoverBackgroundPrimary": globals["colorScales"]["navy"]["900"],
    "colorPopoverBackgroundSecondary": globals["colorScales"]["navy"]["800"],
    "colorCodeEditorBackground": globals["colorScales"]["navy"]["900"],
    "colorCodeEditorTextDefault": globals["colorScales"]["slate"]["200"],
    "colorCodeEditorTextHighlight": globals["colorScales"]["orange"]["200"],
    "colorCodeEditorTextHintHighlight": globals["colorScales"]["green"]["200"],
    "colorCodeEditorTextGutter": globals["colorScales"]["navy"]["400"],
    "colorCodeEditorCodeSelectedBackground": globals["colorScales"]["navy"]["800"],
    "colorCodeEditorCodeLinenumberColor": globals["colorScales"]["slate"]["400"],
    "colorCodeEditorCodeCursorBorderLeft": globals["colorScales"]["navy"]["300"],
    "colorCodeEditorCodeCursorBorderRight": globals["colorScales"]["navy"]["300"],
    "colorCodeEditorCodeActivelineBackground": globals["colorScales"]["navy"]["200"],
    "colorCodeEditorCodeFatCursorBackground": globals["colorScales"]["navy"]["300"],
    "colorCodeEditorCodeAtomNumberKeywordVariableAttributeQuoteColor": globals["colorScales"]["blue"]["400"],
    "colorCodeEditorCodeVariableColor": globals["colorScales"]["purple"]["300"],
    "colorCodeEditorCodePropertyColor": globals["colorScales"]["orange"]["300"],
    "colorCodeEditorCodePunctuationUnitNegativeColor": globals["colorScales"]["red"]["200"],
    "colorCodeEditorCodeStringOperatorColor": globals["colorScales"]["green"]["300"],
    "colorCodeEditorCodePositiveColor": globals["colorScales"]["green"]["400"],
    "colorCodeEditorCodeVariable2Variable3String2UrlColor": globals["colorScales"]["blue"]["300"],
    "colorCodeEditorCodeDefTagBuiltinQualifierHeaderEmColor": globals["colorScales"]["red"]["300"],
    "colorCodeEditorCodeBracketCommentColor": globals["colorScales"]["grey"]["400"],
    "colorCodeEditorCodeErrorInvalidcharColor": globals["colorScales"]["red"]["400"],
    "colorCodeEditorCodeMatchingbracketColor": globals["colorScales"]["slate"]["400"],
    "colorCodeEditorCodeHintsBackground": globals["colorScales"]["navy"]["800"],
    "colorCodeEditorCodeHint": globals["colorScales"]["navy"]["100"],
    "colorCodeEditorCodeHintActiveBackground": globals["colorScales"]["navy"]["800"],
    "colorCodeEditorToolbarBackground": globals["colorScales"]["navy"]["800"],
    "colorCodeEditorToolbarButtonHover": globals["colorScales"]["navy"]["900"],
    "shadowExtraSmall": "0 1px 8px 0 rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.06)",
    "shadowSmall": "0 1px 1px 0 rgba(0,0,0,0.16), 0 1px 8px 0 rgba(0,0,0,0.16)",
    "shadowMedium": "0 0 4px 0 rgba(0,0,0,0.24), 0 6px 12px 0 rgba(0,0,0,0.30)",
    "shadowLarge": "0 2px 6px 0 rgba(0,0,0,0.26), 0 24px 32px -4px rgba(0,0,0,0.42)",
    "shadowFocusDefault": "0 0 0 3px #90CEFF"
};
