import React from "react";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../routing/OctopusRoutingContext";
import { useOctopusLinkComponent, useIsUrlActive } from "../../routing/OctopusRoutingContext";
import { navigationBarIconLinkStyles } from "./navigationBarStyles";
export interface NavigationBarIconLinkProps {
    href: DesignSystemLinkHref;
    showLinkAsActive?: ShowLinkAsActive;
    icon: React.ReactNode;
    accessibleName: string;
}
export function NavigationBarIconLink({ href, showLinkAsActive, icon, accessibleName }: NavigationBarIconLinkProps) {
    const Link = useOctopusLinkComponent();
    const isUrlActive = useIsUrlActive();
    const isActive = showLinkAsActive === "never" ? false : isUrlActive(href, showLinkAsActive === "if path matches exactly");
    return (<Link className={navigationBarIconLinkStyles} aria-current={isActive ? "page" : undefined} href={href} aria-label={accessibleName}>
            {icon}
        </Link>);
}
