import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
export function SmsIcon() {
    return (<svg className={styles} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6 0H1.4C0.63 0 0.00699999 0.63 0.00699999 1.4L0 14L2.8 11.2H12.6C13.37 11.2 14 10.57 14 9.8V1.4C14 0.63 13.37 0 12.6 0ZM4.9 6.3H3.5V4.9H4.9V6.3ZM7.7 6.3H6.3V4.9H7.7V6.3ZM10.5 6.3H9.1V4.9H10.5V6.3Z"/>
        </svg>);
}
const styles = css(iconStyles, {
    width: 16,
    height: 16,
    fill: themeTokens.color.icon.subtle,
});
