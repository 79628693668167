import { css } from "@emotion/css";
import * as React from "react";
import type { DesignSystemLinkHref } from "../../routing/OctopusRoutingContext";
import { useOctopusLinkComponent } from "../../routing/OctopusRoutingContext";
import { resetStyles } from "../../utils/resetStyles";
import { actionButtonRootCss, actionButtonDisabledCss, actionButtonDisabledWithBackgroundCss, primaryActionButtonCss, secondaryActionButtonCss, ternaryActionButtonCss, loudActionButtonCss } from "./ActionButton";
export interface NavigationButtonProps {
    /**
     * The label of the button.
     */
    label: string;
    /**
     * The title of the button.
     *
     * This is shown when hovering over the button.
     */
    titleAltText?: string;
    /**
     * The URL that the button points to.
     */
    href: DesignSystemLinkHref;
    /**
     * The icon to show next to the label.
     *
     * This is always shown to the left of the label.
     */
    icon?: JSX.Element;
    /**
     * Controls whether the href is external.
     *
     * External buttons will open their links in a new window.
     */
    external?: boolean;
    /**
     * Controls whether the button is disabled.
     */
    disabled?: boolean;
    /**
     * Controls the appearance of the button.
     */
    type?: NavigationButtonType;
    /**
     * The accessible name of the button.
     */
    accessibleName?: string;
    /**
     * The action to perform when the button is clicked.
     */
    onClick?: () => void;
}
export enum NavigationButtonType {
    Primary,
    Secondary,
    Ternary,
    CreateRelease,
    Loud
}
export function NavigationButton({ label, titleAltText, href, icon, external, disabled = false, type = NavigationButtonType.Secondary, accessibleName, onClick }: NavigationButtonProps) {
    const Link = useOctopusLinkComponent();
    const handleOnClick = React.useCallback(() => {
        if (onClick) {
            onClick();
        }
    }, [onClick]);
    const buttonStyles = getNavigationButtonStyles(type);
    const sharedLinkProps: React.HTMLAttributes<HTMLAnchorElement> = {
        className: buttonStyles,
        title: titleAltText ? titleAltText : label,
        ["aria-label"]: accessibleName,
        role: "link",
    };
    const linkContent = (<>
            {icon && <span className={navigationButtonIconStyles}>{icon}</span>}
            {label}
        </>);
    // The Link component could be any externally provided link component e.g. from react router.
    // This Link may not support disabled links since this is not valid HTML, so an href must be provided.
    // We want NavigationButton to support a disabled state so here we switch to a raw anchor element so we have more control over the href.
    // We also use a raw anchor element for external links, which don't need any special handling
    if (disabled) {
        return (<a {...sharedLinkProps} aria-disabled={true}>
                {linkContent}
            </a>);
    }
    if (external) {
        const stringHref = typeof href === "string" ? href : undefined;
        return (<a {...sharedLinkProps} target="_blank" rel="noopener noreferrer" href={stringHref} onClick={handleOnClick}>
                {linkContent}
            </a>);
    }
    return (<Link {...sharedLinkProps} target="_self" href={href} onClick={handleOnClick}>
            {linkContent}
        </Link>);
}
function getNavigationButtonStyles(navigationButtonType: NavigationButtonType) {
    switch (navigationButtonType) {
        case NavigationButtonType.Primary:
        case NavigationButtonType.CreateRelease:
            return primaryButtonStyles;
        case NavigationButtonType.Ternary:
            return ternaryButtonStyles;
        case NavigationButtonType.Loud:
            return loudButtonStyles;
        default:
            return secondaryButtonStyles;
    }
}
const navigationButtonRootStyles = css(resetStyles.anchor, actionButtonRootCss);
const navigationButtonDisabledStyles = css({
    "[aria-disabled=true]&": actionButtonDisabledCss,
});
const navigationButtonDisabledWithBackgroundStyles = css(navigationButtonDisabledStyles, {
    "[aria-disabled=true]&": actionButtonDisabledWithBackgroundCss,
});
const navigationButtonIconStyles = css({
    display: "flex",
    fontSize: 24,
    marginLeft: -4,
    marginRight: 4,
});
const primaryButtonStyles = css(navigationButtonRootStyles, navigationButtonDisabledWithBackgroundStyles, primaryActionButtonCss);
const secondaryButtonStyles = css(navigationButtonRootStyles, navigationButtonDisabledWithBackgroundStyles, secondaryActionButtonCss);
const ternaryButtonStyles = css(navigationButtonRootStyles, navigationButtonDisabledStyles, ternaryActionButtonCss);
const loudButtonStyles = css(navigationButtonRootStyles, navigationButtonDisabledStyles, loudActionButtonCss);
