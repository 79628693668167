import { css, cx } from "@emotion/css";
import type { OctopusTheme } from "@octopusdeploy/design-system-tokens";
import { darkTheme, lightTheme } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren } from "react";
import React, { createContext, useMemo } from "react";
export interface ThemeProps {
    themeName: ThemeName;
    isFullHeight?: boolean;
}
const DesignSystemThemeContext = createContext<ThemeName | undefined>(undefined);
export const DesignSystemThemeProvider = DesignSystemThemeContext.Provider;
export function useDesignSystemTheme() {
    const contextValue = React.useContext(DesignSystemThemeContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a DesignSystemThemeProvider");
    }
    return contextValue;
}
export function Theme({ themeName, isFullHeight, children }: PropsWithChildren<ThemeProps>) {
    const themeCssVariableProps = useMemo(() => {
        const theme: OctopusTheme = themeName === "light" ? lightTheme : darkTheme;
        return Object.entries(theme).reduce((prev, [cssVariableName, value]) => {
            return {
                ...prev,
                [`--${cssVariableName}`]: value,
            };
        }, {});
    }, [themeName]);
    return (<DesignSystemThemeProvider value={themeName}>
            <div style={themeCssVariableProps} className={cx({ [fullHeightStyles]: isFullHeight })}>
                {children}
            </div>
        </DesignSystemThemeProvider>);
}
const fullHeightStyles = css({
    height: "100%",
});
export type ThemeName = "light" | "dark";
