import { css, cx } from "@emotion/css";
import { ClickAwayListener, Fade, Popper } from "@material-ui/core";
import { borderRadius, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import type { PropsWithChildren, ReactNode } from "react";
interface Props {
    placement: PopoverPlacement;
    width?: PopoverWidth;
    children: ReactNode;
    onClose?: () => void;
    anchorEl: null | HTMLElement;
    open: boolean;
}
export type PopoverProps = Omit<Props, "children">;
export const popoverPlacementOptions = ["bottom-end", "bottom-start", "top-start", "top-end", "left-start", "left-end", "right-start", "right-end"] as const;
export type PopoverPlacement = (typeof popoverPlacementOptions)[number];
export const popoverWidthOptions = ["narrow", "wide"] as const;
export type PopoverWidth = (typeof popoverWidthOptions)[number];
export const Popover: React.FC<PropsWithChildren<Props>> = ({ children, open, anchorEl, placement = "left-start", width = "narrow", onClose }) => {
    const popoverStyles = {
        paper: css({
            zIndex: "9999",
            backgroundColor: themeTokens.color.popover.background.primary,
            boxShadow: themeTokens.shadow.medium,
            borderRadius: borderRadius.large,
            maxWidth: width === "narrow" ? "328px" : "400px",
            minHeight: "112px",
            font: text.interface.body.default.medium,
            color: themeTokens.color.text.primary,
        }),
        placement: {
            "bottom-start": css({
                ":after": {
                    top: "0px",
                    left: "0px",
                    marginLeft: "63px",
                    transform: "rotate(135deg)",
                },
            }),
            "bottom-end": css({
                ":after": {
                    top: "0px",
                    left: "100%",
                    marginLeft: "-33px",
                    transform: "rotate(135deg)",
                },
            }),
            "top-start": css({
                ":after": {
                    bottom: "-20px",
                    left: "0",
                    marginLeft: "33px",
                    transform: "rotate(-45deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            "top-end": css({
                ":after": {
                    bottom: "-20px",
                    left: "100%",
                    marginLeft: "-60px",
                    transform: "rotate(-45deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            "left-start": css({
                ":after": {
                    top: "3px",
                    right: "-20px",
                    marginTop: "60px",
                    transform: "rotate(-135deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            "left-end": css({
                ":after": {
                    bottom: "13px",
                    right: "-20px",
                    transform: "rotate(-135deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            "right-start": css({
                ":after": {
                    top: "0px",
                    left: "2px",
                    marginTop: "30px",
                    transform: "rotate(45deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            "right-end": css({
                ":after": {
                    bottom: "43px",
                    left: "2px",
                    transform: "rotate(45deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            bottom: css({
                ":after": {
                    bottom: "43px",
                    left: "2px",
                    transform: "rotate(45deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
        },
    };
    const popoverContentStyles = {
        paper: css({
            padding: space[24],
            ":after": {
                content: "\"\"",
                position: "absolute",
                width: "0",
                height: "0",
                boxSizing: "border-box",
                border: `10px solid ${themeTokens.color.popover.background.primary}`,
                borderColor: `${themeTokens.color.popover.background.primary} ${themeTokens.color.popover.background.primary} transparent transparent`,
                background: themeTokens.color.popover.background.primary,
                transformOrigin: "0 0",
                boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
            },
        }),
    };
    const onCloseClicked = () => {
        onClose?.();
    };
    return (<Popper className={cx(popoverStyles.paper, popoverStyles.placement[placement])} open={open} anchorEl={anchorEl} placement={placement} modifiers={{
            flip: {
                enabled: false,
            },
            offset: { enabled: true, offset: getOffsetValue(placement) },
        }} transition>
            {({ TransitionProps }) => (<ClickAwayListener onClickAway={onCloseClicked}>
                    <Fade {...TransitionProps} timeout={350}>
                        <div className={cx(popoverContentStyles.paper, popoverStyles.placement[placement])}>{children}</div>
                    </Fade>
                </ClickAwayListener>)}
        </Popper>);
};
Popover.displayName = "Popover"
const getOffsetValue = (placement: PopoverPlacement): string => {
    switch (placement) {
        case "left-end":
            return "35,15";
        case "left-start":
            return "-35,15";
        case "right-end":
            return "35,15";
        case "right-start":
            return "-35,15";
        case "top-end":
            return "35,15";
        case "top-start":
            return "-35,15";
        case "bottom-end":
            return "35,15";
        case "bottom-start":
            return "-35,15";
    }
};
