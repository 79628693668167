import { css } from "@emotion/css";
import { Fade } from "@material-ui/core";
import { useLayoutEffect, useState } from "react";
import * as React from "react";
export interface SpotlightOverlayProps {
    target: HTMLDivElement | undefined;
    onOverlayClick: () => void;
    show: boolean;
}
export function SpotlightOverlay(props: SpotlightOverlayProps) {
    const { target, onOverlayClick, show } = props;
    const [targetSize, setTargetSize] = useState<number[]>([0, 0]);
    const [targetPosition, setTargetPosition] = useState<number[]>([0, 0]);
    useLayoutEffect(() => {
        if (!target)
            return;
        function updateSize() {
            if (!target)
                return;
            const elementRect = target.getBoundingClientRect();
            const { width, height, left } = elementRect;
            const top = getElementTopPosition(elementRect, padding);
            setTargetSize([width, height]);
            setTargetPosition([left, top]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, [target]);
    const padding = 8;
    const body = document.body;
    const html = document.documentElement;
    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const [targetWidth, targetHeight] = targetSize;
    const [targetLeftPos, targetTopPos] = targetPosition;
    const styles = {
        container: css({
            width: "100%",
            height,
            overflow: "hidden",
            zIndex: "100",
            backgroundColor: "#00000051",
            position: "absolute",
            top: 0,
            bottom: 0,
            mixBlendMode: "hard-light",
        }),
        spotlight: css({
            height: Math.round(targetHeight) + padding * 2,
            width: Math.round(targetWidth) + padding * 2,
            left: Math.round(targetLeftPos) - padding,
            top: targetTopPos,
            position: "absolute",
            backgroundColor: "grey",
            borderRadius: "16px",
        }),
    };
    const child = target === undefined ? (<></>) : (<Fade in={show} timeout={500}>
                <div className={styles.container} onClick={onOverlayClick}>
                    <div className={styles.spotlight}></div>
                </div>
            </Fade>);
    return <div>{child}</div>;
}
function getElementTopPosition(elementRect: DOMRect, offset: number): number {
    const parent = document.scrollingElement ?? document.documentElement;
    let parentTop = 0;
    if (parent instanceof HTMLElement) {
        parentTop = parent.scrollTop;
    }
    const top = (elementRect?.top ?? 0) + parentTop;
    return Math.floor(top - offset);
}
