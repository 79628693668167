import { css } from "@emotion/css";
import React from "react";
import { useDesignSystemTheme } from "../../Theme";
export function EmptyStateInsightsIllustration() {
    const currentTheme = useDesignSystemTheme();
    return currentTheme === "light" ? <IllustrationInLightTheme /> : <IllustrationInDarkTheme />;
}
function IllustrationInDarkTheme() {
    return (<svg className={illustrationStyles} viewBox="0 0 460 460" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M201.153 11.6366C272.586 -9.96488 333.275 69.2634 379.383 128.01C424.957 186.076 469.808 256.149 448.573 326.874C428.246 394.573 352.317 423.874 284.574 443.78C231.578 459.352 179.174 443.437 128.761 420.85C75.7014 397.076 2.18345 374.457 0.0361676 316.292C-2.07997 258.971 89.2472 254.046 120.836 206.194C161.495 144.603 130.568 32.9817 201.153 11.6366Z" fill="#1F303F"/>
            <path d="M183.492 330.647L182.897 334.187L188.169 347.011L190.998 345.557L188.245 333.556L187.849 328.977L183.492 330.647Z" fill="#A0616A"/>
            <path d="M189.311 310.732C189.311 310.732 186.551 309.395 184.409 313.912C182.267 318.43 182.532 328.339 182.532 328.339C182.532 328.339 181.752 329.081 182.248 329.601C182.743 330.121 183.204 330.946 182.699 331.897C182.433 332.398 181.817 333.379 182.826 333.658C183.834 333.936 188.553 332.62 188.553 332.62C188.553 332.62 190.318 331.83 189.315 331.297C188.313 330.765 189.725 327.545 189.725 327.545L190.891 318.243L189.311 310.732Z" fill="#1A77CA"/>
            <path d="M191.478 351.911C192.586 351.57 193.02 349.782 192.447 347.916C191.874 346.05 190.511 344.813 189.403 345.154C188.296 345.494 187.862 347.282 188.435 349.148C189.008 351.014 190.37 352.251 191.478 351.911Z" fill="#A0616A"/>
            <path d="M217.605 406.871L220.247 406.162L218.769 395.634L214.87 396.68L217.605 406.871Z" fill="#A0616A"/>
            <path d="M226.641 404.382C226.761 404.499 226.924 404.934 226.967 405.095C227.1 405.59 226.806 406.1 226.311 406.233L218.129 408.428C217.791 408.519 217.444 408.318 217.353 407.98L217.262 407.64C217.262 407.64 216.582 406.725 217.077 405.239C217.077 405.239 218.378 405.949 219.51 403.986L219.745 403.037L223.697 404.567L225.588 404.301C226.002 404.243 226.342 404.09 226.641 404.382Z" fill="#111A23"/>
            <path d="M191.71 406.81L194.445 406.81L195.746 396.259L191.709 396.259L191.71 406.81Z" fill="#A0616A"/>
            <path d="M201.081 406.748C201.166 406.892 201.211 407.355 201.211 407.521C201.211 408.034 200.796 408.45 200.283 408.45H191.811C191.462 408.45 191.178 408.166 191.178 407.816V407.464C191.178 407.464 190.759 406.404 191.622 405.097C191.622 405.097 192.694 406.12 194.296 404.518L194.769 403.662L198.189 406.163L200.085 406.397C200.5 406.448 200.868 406.389 201.081 406.748Z" fill="#111A23"/>
            <path d="M209.914 343.959L217.107 374.964L220.579 400.016L215.122 400.761L199.992 358.098L196.52 400.761L190.319 401.257C190.319 401.257 185.358 350.657 187.59 344.456L209.914 343.959Z" fill="#111A23"/>
            <path d="M218.476 307.297L220.958 304.703L223.808 291.134L220.651 290.747L216.181 302.219L213.934 306.229L218.476 307.297Z" fill="#A0616A"/>
            <path d="M202.467 320.495C202.467 320.495 204.79 324.777 209.101 322.245C213.412 319.714 217.971 309.745 217.971 309.745C217.971 309.745 218.882 307.211 219.834 306.708C220.336 306.443 221.628 306.158 220.95 305.36C220.272 304.563 215.399 302.819 215.399 302.819C215.399 302.819 213.496 302.481 214.026 303.485C214.555 304.488 212.815 303.867 212.991 304.929C213.167 305.991 211.577 306.358 211.577 306.358L202.467 320.495Z" fill="#1A77CA"/>
            <path d="M224.79 289.217C225.365 287.352 224.934 285.563 223.826 285.222C222.719 284.88 221.355 286.115 220.78 287.981C220.205 289.846 220.636 291.635 221.744 291.976C222.851 292.318 224.215 291.083 224.79 289.217Z" fill="#A0616A"/>
            <path d="M202.774 305.656H195.983L195.708 307.798C195.708 307.798 187.143 310.153 187.357 311.438C187.572 312.723 188.856 334.135 188.856 334.135L187.786 344.841C187.786 344.841 207.057 352.978 210.054 343.342L208.556 332.208C208.556 332.208 212.196 313.151 211.339 312.294C210.483 311.438 203.417 308.012 203.417 308.012L202.774 305.656Z" fill="#1A77CA"/>
            <path d="M198.83 305.683C202.196 305.683 204.926 302.954 204.926 299.588C204.926 296.221 202.196 293.492 198.83 293.492C195.463 293.492 192.734 296.221 192.734 299.588C192.734 302.954 195.463 305.683 198.83 305.683Z" fill="#A0616A"/>
            <path d="M203.344 297.08C204.123 297.014 204.902 296.948 205.68 296.882C205.413 296.76 205.134 296.628 204.955 296.394C204.777 296.16 204.74 295.789 204.956 295.588C205.207 295.354 205.624 295.478 205.9 295.685C205.748 294.894 205.276 294.169 204.613 293.71C203.804 293.151 201.802 293.818 200.827 293.69C199.701 293.541 199.561 292.582 198.435 292.433C198.011 292.378 197.533 292.335 197.2 292.603C196.83 292.901 196.788 293.463 196.462 293.807C195.996 294.299 195.197 294.154 194.533 294.292C193.594 294.488 192.885 295.312 192.558 296.215C192.231 297.117 192.219 298.098 192.211 299.058C192.202 300.134 192.198 301.379 192.976 302.122C193.45 302.574 194.741 304.127 194.881 304.768C194.98 305.222 199.019 305.776 199.895 305.751L202.879 304.768C203.172 303.476 202.687 300.922 201.99 299.796C202.219 300.099 203.281 301.012 203.444 300.668C203.606 300.324 203.532 299.246 203.734 298.923C203.989 298.517 202.866 297.12 203.344 297.08Z" fill="#111A23"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M251.439 304.321C249.306 303.444 247.804 301.345 247.804 298.895C247.804 295.656 250.429 293.03 253.669 293.03C256.908 293.03 259.533 295.656 259.533 298.895C259.533 301.357 258.017 303.464 255.867 304.334L256.121 307.341H251.084L251.439 304.321Z" fill="#FFDACA"/>
            <path d="M248.861 392.192L246.23 392.191L244.978 382.042L248.861 382.043L248.861 392.192Z" fill="#FFDACA"/>
            <path d="M239.846 392.132C239.764 392.27 239.721 392.715 239.721 392.876C239.721 393.369 240.121 393.769 240.614 393.769H248.763C249.099 393.769 249.372 393.496 249.372 393.16V392.82C249.372 392.82 249.775 391.801 248.945 390.544C248.945 390.544 247.914 391.528 246.372 389.987L245.918 389.163L242.628 391.57L240.804 391.794C240.405 391.843 240.051 391.786 239.846 392.132Z" fill="#041A2D"/>
            <path d="M258.844 392.192L256.213 392.191L254.961 382.042L258.845 382.043L258.844 392.192Z" fill="#FFDACA"/>
            <path d="M249.83 392.132C249.748 392.27 249.704 392.715 249.704 392.876C249.704 393.369 250.104 393.769 250.597 393.769H258.746C259.082 393.769 259.355 393.496 259.355 393.16V392.82C259.355 392.82 259.758 391.801 258.928 390.544C258.928 390.544 257.897 391.528 256.356 389.987L255.901 389.163L252.611 391.57L250.787 391.794C250.388 391.843 250.035 391.786 249.83 392.132Z" fill="#041A2D"/>
            <path d="M242.661 337.661C242.661 337.661 242.304 340.766 241.965 344.521C241.626 348.276 244.822 386.486 244.822 386.486L250.036 386.819L252.144 350.321L254.141 386.93L260.353 386.264L261.827 339.79L242.661 337.661Z" fill="#041A2D"/>
            <path d="M257.048 307.61L250.37 306.39L249.021 307.738L242.16 310.605L242.8 331.04C242.8 331.04 241.668 333.223 242.16 335.639C242.633 337.953 242.368 340.339 242.368 340.339L261.828 340.114L261.02 327.911L263.238 312.38L258.054 309.926L257.048 307.61Z" fill="#A9BBCB"/>
            <path d="M252.206 332.684C254.104 333.137 255.875 332.525 256.163 331.317C256.451 330.11 255.147 328.764 253.249 328.311C252.493 328.12 251.703 328.113 250.944 328.289L242.881 326.472L242.108 330.274L250.139 331.662C250.737 332.163 251.446 332.513 252.206 332.684Z" fill="#FFDACA"/>
            <path d="M243.491 311.715L242.16 310.605C242.16 310.605 239.497 311.936 239.054 313.046C238.61 314.155 235.947 324.805 235.947 324.805C235.947 324.805 235.425 330.642 236.796 331.384C238.166 332.127 246.819 331.683 246.819 331.683L247.485 327.246L243.935 325.915L243.491 311.715Z" fill="#A9BBCB"/>
            <path d="M257.873 303.967C258.575 304.03 259.722 302.233 259.671 300.492C259.638 299.372 260.61 299.152 260.683 297.708C260.725 296.866 259.432 296.604 259.343 295.929C259.182 294.706 258.393 293.883 258.295 293.783C257.445 292.92 256.845 293.201 255.845 292.2C255.243 291.597 255.397 291.432 254.992 291.165C253.822 290.394 251.719 291.23 250.462 292.356C250.027 292.745 248.405 292.319 247.697 293.42C246.862 294.716 247.056 297.36 247.414 297.709C247.631 297.921 248.128 297.403 249.474 297.071C250.72 296.763 249.544 298.285 250.985 298.029C252.688 297.727 253.022 297.209 253.66 297.52C254.334 297.849 254.861 298.866 254.708 299.666C254.632 300.063 254.412 300.284 254.536 300.486C254.763 300.854 255.023 300.493 255.313 300.863C255.651 301.293 254.811 302.396 254.969 302.503C255.001 302.524 255.098 302.521 256.004 301.65C256.366 301.301 256.472 301.088 256.543 301.121C256.658 301.174 257.409 301.874 257.122 302.426C256.676 303.287 257.247 303.314 257.122 303.893C256.993 304.49 257.426 303.927 257.873 303.967Z" fill="#041A2D"/>
            <path d="M256.6 324.871L257.062 325.245L254.64 328.864L254.179 328.739L256.6 324.871Z" fill="#111A23"/>
            <path d="M256.155 333.597C254.426 334.499 253.49 336.123 254.065 337.223C254.639 338.324 256.507 338.484 258.236 337.581C258.932 337.229 259.534 336.717 259.991 336.085L267.269 332.169L265.358 328.792L258.386 333.012C257.606 333.026 256.842 333.226 256.155 333.597Z" fill="#FFDACA"/>
            <path d="M261.906 312.602C261.906 312.602 263.903 311.271 265.234 314.821C266.284 317.622 268.232 325.877 269.008 329.237C269.214 330.129 268.916 331.061 268.232 331.669L267.342 332.46L263.016 335.677L260.066 331.384L263.648 329.224L259.562 320.361L261.906 312.602Z" fill="#A9BBCB"/>
            <path d="M246.671 330.229L247.165 328.845L256.362 330.427L264.173 326.472L264.569 327.559L256.065 332.603L246.671 330.229Z" fill="#E6E6E6"/>
            <path d="M264.965 326.867L256.165 331.317L246.105 329.863L247.067 334.086L255.571 335.569L263.611 331.717L264.965 326.867Z" fill="#111A23"/>
            <path d="M135.154 93.932C135.476 93.0613 136.707 93.0613 137.03 93.932L138.781 98.6637C138.882 98.9375 139.098 99.1533 139.371 99.2545L144.103 101.005C144.974 101.328 144.974 102.559 144.103 102.881L139.371 104.632C139.098 104.733 138.882 104.949 138.781 105.223L137.03 109.955C136.707 110.825 135.476 110.825 135.154 109.955L133.403 105.223C133.302 104.949 133.086 104.733 132.812 104.632L128.08 102.881C127.21 102.559 127.21 101.328 128.08 101.005L132.812 99.2545C133.086 99.1533 133.302 98.9375 133.403 98.6637L135.154 93.932Z" fill="#449BE1"/>
            <path d="M162.271 71.8358C162.594 70.9652 163.825 70.9652 164.147 71.8358L166.712 78.7668C166.813 79.0406 167.029 79.2564 167.303 79.3576L174.234 81.9224C175.104 82.2445 175.104 83.4759 174.234 83.7981L167.303 86.3628C167.029 86.4641 166.813 86.6799 166.712 86.9536L164.147 93.8847C163.825 94.7553 162.594 94.7553 162.271 93.8847L159.707 86.9536C159.605 86.6799 159.39 86.4641 159.116 86.3628L152.185 83.7981C151.314 83.4759 151.314 82.2445 152.185 81.9224L159.116 79.3576C159.39 79.2564 159.605 79.0406 159.707 78.7668L162.271 71.8358Z" fill="#155EA0"/>
            <path d="M346.55 386.155C346.227 387.026 344.996 387.026 344.674 386.155L341.431 377.392C341.33 377.118 341.114 376.902 340.84 376.801L332.076 373.558C331.206 373.236 331.206 372.004 332.076 371.682L340.84 368.439C341.114 368.338 341.33 368.122 341.431 367.849L344.674 359.085C344.996 358.214 346.227 358.214 346.55 359.085L349.793 367.849C349.894 368.122 350.11 368.338 350.383 368.439L359.147 371.682C360.018 372.004 360.018 373.236 359.147 373.558L350.383 376.801C350.11 376.902 349.894 377.118 349.793 377.392L346.55 386.155Z" fill="#449BE1"/>
            <path d="M305.26 419.985C304.938 420.856 303.707 420.856 303.385 419.985L298.785 407.556C298.684 407.282 298.468 407.066 298.194 406.965L285.765 402.366C284.895 402.044 284.895 400.812 285.765 400.49L298.194 395.891C298.468 395.789 298.684 395.574 298.785 395.3L303.385 382.871C303.707 382 304.938 382 305.26 382.871L309.859 395.3C309.961 395.574 310.177 395.789 310.45 395.891L322.88 400.49C323.75 400.812 323.75 402.044 322.88 402.366L310.45 406.965C310.177 407.066 309.961 407.282 309.859 407.556L305.26 419.985Z" fill="#155EA0"/>
            <mask id="mask0_477_36306" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="60" y="217" width="126" height="74">
                <rect x="60.1152" y="217.754" width="125.519" height="72.4865" rx="2" fill="#102438"/>
            </mask>
            <g mask="url(#mask0_477_36306)">
                <rect x="60" y="217.809" width="125.813" height="72.2294" fill="#2E475D"/>
                <path d="M59.1455 278.516C65.3795 277.864 70.8254 263.111 81.7499 263.11C92.6744 263.11 94.219 240.758 104.629 240.758C115.039 240.758 117.01 252.059 126.273 252.059C135.535 252.059 135.702 247.14 145.639 247.14C155.577 247.14 157.998 256.855 166.673 256.855C175.349 256.855 178.5 251.628 185.621 250.883" stroke="#1A77CA"/>
                <path d="M59.1455 290.916C65.3795 290.264 70.8248 282.809 81.7493 282.809C92.6738 282.808 95.4562 233.391 105.866 233.391C116.276 233.391 117.01 264.459 126.273 264.459C135.535 264.459 135.299 248.612 145.236 248.612C155.173 248.612 157.998 269.255 166.673 269.255C175.349 269.255 178.5 264.028 185.621 263.283" stroke="#1A77CA" strokeOpacity="0.2"/>
            </g>
            <mask id="mask1_477_36306" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="98" y="146" width="88" height="50">
                <rect x="98" y="146" width="88" height="50" rx="2" fill="#102438"/>
            </mask>
            <g mask="url(#mask1_477_36306)">
                <rect x="98" y="146.535" width="87.8696" height="49.4905" rx="2" fill="#2E475D"/>
                <path d="M97.3535 187.706C101.628 187.255 105.417 181.498 112.905 181.455C120.393 181.412 121.114 172.123 128.249 172.082C135.384 172.042 136.714 187.481 143.063 187.444C149.412 187.408 156.228 184.834 163.039 184.795C169.85 184.756 171.648 187.28 177.594 187.246C183.541 187.212 181.117 188.516 186 188" stroke="#8C5FC7" strokeWidth="0.5"/>
                <path d="M97.4033 192.131L97.58 192.113C101.87 191.673 106.009 191.248 113.536 191.248C121.165 191.248 122.482 188.363 129.752 188.363C137.023 188.363 137.817 174.004 144.286 174.004C150.755 174.004 150.808 181.075 157.749 181.075C164.689 181.075 166.443 177.29 172.502 177.29C178.561 177.29 180.771 181.586 185.745 181.075" stroke="#C5AEEE" strokeWidth="0.5"/>
            </g>
            <mask id="mask2_477_36306" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="203" y="126" width="243" height="140">
                <rect x="203.887" y="126.55" width="242.052" height="138.603" rx="4" fill="#102438"/>
            </mask>
            <g mask="url(#mask2_477_36306)">
                <rect x="203.586" y="127.115" width="242.62" height="138.111" rx="4" fill="#2E475D"/>
                <path d="M201.208 186.453C213.23 185.206 220.756 161.89 238.643 161.891C256.531 161.892 256.731 179.254 277.598 180.961C298.465 182.669 304.526 199.898 321.47 200.231C338.414 200.565 343.184 180.961 365.216 180.961C387.248 180.961 392.231 166.482 405.194 165.877C418.157 165.273 433.423 171.383 447.156 169.958" stroke="#5ECD9E" strokeWidth="2"/>
                <path d="M201.829 242.097C222.487 242.097 221.968 214.119 243.016 214.121C264.064 214.122 259.514 162.974 280.381 164.681C301.248 166.389 304.776 189.162 321.72 189.495C338.664 189.828 345.64 200.498 367.672 200.498C389.704 200.498 394.687 186.019 407.65 185.414C420.613 184.81 435.88 190.92 449.612 189.495" stroke="#5ECD9E" strokeOpacity="0.2" strokeWidth="2"/>
                <path d="M274.873 249.504H272.831L272.844 248.201H274.873C275.464 248.201 275.959 248.072 276.357 247.815C276.76 247.557 277.062 247.188 277.263 246.708C277.469 246.227 277.572 245.655 277.572 244.992V244.474C277.572 243.959 277.513 243.503 277.395 243.106C277.281 242.708 277.11 242.374 276.882 242.103C276.659 241.833 276.383 241.628 276.055 241.488C275.731 241.348 275.357 241.278 274.932 241.278H272.792V239.968H274.932C275.567 239.968 276.147 240.075 276.672 240.289C277.198 240.499 277.651 240.802 278.031 241.2C278.417 241.597 278.712 242.073 278.918 242.627C279.124 243.182 279.226 243.802 279.226 244.487V244.992C279.226 245.677 279.124 246.297 278.918 246.852C278.712 247.406 278.417 247.882 278.031 248.28C277.646 248.673 277.187 248.976 276.653 249.19C276.123 249.4 275.53 249.504 274.873 249.504ZM273.744 239.968V249.504H272.096V239.968H273.744Z" fill="white"/>
                <path d="M287.139 248.201V249.504H282.063V248.201H287.139ZM282.529 239.968V249.504H280.881V239.968H282.529ZM286.475 243.95V245.234H282.063V243.95H286.475ZM287.106 239.968V241.278H282.063V239.968H287.106Z" fill="white"/>
                <path d="M292.116 245.948H289.627V244.645H292.116C292.549 244.645 292.899 244.575 293.166 244.435C293.433 244.295 293.628 244.103 293.751 243.859C293.878 243.61 293.941 243.326 293.941 243.007C293.941 242.706 293.878 242.424 293.751 242.162C293.628 241.896 293.433 241.682 293.166 241.521C292.899 241.359 292.549 241.278 292.116 241.278H290.133V249.504H288.485V239.968H292.116C292.855 239.968 293.484 240.099 294 240.361C294.521 240.619 294.917 240.977 295.189 241.435C295.46 241.89 295.596 242.409 295.596 242.994C295.596 243.61 295.46 244.138 295.189 244.579C294.917 245.02 294.521 245.359 294 245.594C293.484 245.83 292.855 245.948 292.116 245.948Z" fill="white"/>
                <path d="M303.061 248.201V249.504H298.262V248.201H303.061ZM298.721 239.968V249.504H297.073V239.968H298.721Z" fill="white"/>
                <path d="M311.525 244.474V244.998C311.525 245.719 311.431 246.365 311.243 246.937C311.055 247.509 310.785 247.996 310.435 248.397C310.089 248.799 309.673 249.107 309.188 249.321C308.702 249.531 308.163 249.635 307.572 249.635C306.986 249.635 306.449 249.531 305.964 249.321C305.482 249.107 305.064 248.799 304.709 248.397C304.355 247.996 304.079 247.509 303.882 246.937C303.69 246.365 303.593 245.719 303.593 244.998V244.474C303.593 243.754 303.69 243.11 303.882 242.542C304.075 241.97 304.346 241.483 304.696 241.082C305.051 240.676 305.469 240.368 305.95 240.158C306.436 239.944 306.973 239.837 307.559 239.837C308.15 239.837 308.689 239.944 309.174 240.158C309.66 240.368 310.078 240.676 310.429 241.082C310.779 241.483 311.048 241.97 311.236 242.542C311.429 243.11 311.525 243.754 311.525 244.474ZM309.877 244.998V244.461C309.877 243.929 309.824 243.459 309.719 243.053C309.619 242.643 309.468 242.3 309.266 242.025C309.069 241.745 308.826 241.536 308.538 241.396C308.249 241.252 307.922 241.18 307.559 241.18C307.196 241.18 306.872 241.252 306.587 241.396C306.303 241.536 306.06 241.745 305.859 242.025C305.662 242.3 305.511 242.643 305.405 243.053C305.3 243.459 305.248 243.929 305.248 244.461V244.998C305.248 245.531 305.3 246.003 305.405 246.413C305.511 246.823 305.664 247.171 305.865 247.454C306.071 247.734 306.316 247.946 306.601 248.09C306.885 248.229 307.209 248.299 307.572 248.299C307.94 248.299 308.266 248.229 308.551 248.09C308.835 247.946 309.076 247.734 309.273 247.454C309.47 247.171 309.619 246.823 309.719 246.413C309.824 246.003 309.877 245.531 309.877 244.998Z" fill="white"/>
                <path d="M313.797 239.968L316.016 244.52L318.236 239.968H320.061L316.844 245.994V249.504H315.182V245.994L311.965 239.968H313.797Z" fill="white"/>
                <path d="M321.775 239.968H323.246L326.01 247.323L328.768 239.968H330.238L326.588 249.504H325.419L321.775 239.968ZM321.105 239.968H322.504L322.747 246.334V249.504H321.105V239.968ZM329.51 239.968H330.915V249.504H329.267V246.334L329.51 239.968Z" fill="white"/>
                <path d="M339.129 248.201V249.504H334.053V248.201H339.129ZM334.52 239.968V249.504H332.871V239.968H334.52ZM338.466 243.95V245.234H334.053V243.95H338.466ZM339.096 239.968V241.278H334.053V239.968H339.096Z" fill="white"/>
                <path d="M348.059 239.968V249.504H346.411L342.123 242.673V249.504H340.475V239.968H342.123L346.424 246.813V239.968H348.059Z" fill="white"/>
                <path d="M353.758 239.968V249.504H352.123V239.968H353.758ZM356.759 239.968V241.278H349.149V239.968H356.759Z" fill="white"/>
                <path d="M362.701 239.968V249.504H361.053V239.968H362.701ZM366.601 244.14V245.444H362.281V244.14H366.601ZM367.146 239.968V241.278H362.281V239.968H367.146Z" fill="white"/>
                <path d="M368.46 239.968H371.841C372.568 239.968 373.187 240.078 373.699 240.296C374.212 240.514 374.603 240.837 374.875 241.265C375.151 241.689 375.288 242.213 375.288 242.837C375.288 243.313 375.201 243.732 375.026 244.095C374.851 244.457 374.603 244.763 374.284 245.011C373.964 245.256 373.583 245.446 373.141 245.581L372.642 245.824H369.602L369.589 244.52H371.867C372.261 244.52 372.59 244.45 372.852 244.311C373.115 244.171 373.312 243.981 373.443 243.741C373.579 243.496 373.647 243.221 373.647 242.916C373.647 242.584 373.581 242.296 373.45 242.051C373.323 241.802 373.126 241.612 372.859 241.481C372.592 241.346 372.253 241.278 371.841 241.278H370.108V249.504H368.46V239.968ZM373.916 249.504L371.67 245.221L373.397 245.214L375.676 249.419V249.504H373.916Z" fill="white"/>
                <path d="M383.187 248.201V249.504H378.112V248.201H383.187ZM378.578 239.968V249.504H376.93V239.968H378.578ZM382.524 243.95V245.234H378.112V243.95H382.524ZM383.155 239.968V241.278H378.112V239.968H383.155Z" fill="white"/>
                <path d="M389.596 248.234L392.111 250.225L391.04 251.161L388.572 249.19L389.596 248.234ZM392.13 244.474V244.998C392.13 245.719 392.036 246.365 391.848 246.937C391.66 247.509 391.391 247.996 391.04 248.397C390.69 248.799 390.272 249.107 389.786 249.321C389.3 249.531 388.762 249.635 388.171 249.635C387.585 249.635 387.048 249.531 386.562 249.321C386.081 249.107 385.663 248.799 385.308 248.397C384.954 247.996 384.678 247.509 384.481 246.937C384.288 246.365 384.192 245.719 384.192 244.998V244.474C384.192 243.754 384.288 243.11 384.481 242.542C384.674 241.97 384.945 241.483 385.295 241.082C385.65 240.676 386.068 240.368 386.549 240.158C387.035 239.944 387.571 239.837 388.158 239.837C388.749 239.837 389.287 239.944 389.773 240.158C390.263 240.368 390.684 240.676 391.034 241.082C391.384 241.483 391.653 241.97 391.842 242.542C392.034 243.11 392.13 243.754 392.13 244.474ZM390.476 244.998V244.461C390.476 243.929 390.423 243.459 390.318 243.053C390.218 242.643 390.066 242.3 389.865 242.025C389.668 241.745 389.425 241.536 389.136 241.396C388.852 241.252 388.526 241.18 388.158 241.18C387.799 241.18 387.477 241.252 387.193 241.396C386.908 241.536 386.665 241.745 386.464 242.025C386.263 242.3 386.109 242.643 386.004 243.053C385.899 243.459 385.847 243.929 385.847 244.461V244.998C385.847 245.531 385.899 246.003 386.004 246.413C386.109 246.823 386.263 247.171 386.464 247.454C386.67 247.734 386.915 247.946 387.199 248.09C387.488 248.229 387.812 248.299 388.171 248.299C388.539 248.299 388.865 248.229 389.149 248.09C389.434 247.946 389.675 247.734 389.872 247.454C390.069 247.171 390.218 246.823 390.318 246.413C390.423 246.003 390.476 245.531 390.476 244.998Z" fill="white"/>
                <path d="M399.169 239.968H400.811V246.341C400.811 247.066 400.653 247.673 400.338 248.162C400.023 248.651 399.594 249.02 399.051 249.269C398.513 249.513 397.911 249.635 397.245 249.635C396.558 249.635 395.945 249.513 395.407 249.269C394.868 249.02 394.444 248.651 394.133 248.162C393.827 247.673 393.673 247.066 393.673 246.341V239.968H395.315V246.341C395.315 246.799 395.394 247.177 395.551 247.474C395.709 247.767 395.932 247.983 396.221 248.122C396.51 248.262 396.851 248.332 397.245 248.332C397.639 248.332 397.979 248.262 398.263 248.122C398.552 247.983 398.775 247.767 398.933 247.474C399.09 247.177 399.169 246.799 399.169 246.341V239.968Z" fill="white"/>
                <path d="M408.861 248.201V249.504H403.785V248.201H408.861ZM404.251 239.968V249.504H402.603V239.968H404.251ZM408.198 243.95V245.234H403.785V243.95H408.198ZM408.828 239.968V241.278H403.785V239.968H408.828Z" fill="white"/>
                <path d="M417.791 239.968V249.504H416.143L411.855 242.673V249.504H410.207V239.968H411.855L416.156 246.813V239.968H417.791Z" fill="white"/>
                <path d="M425.401 246.4H427.042C426.99 247.024 426.815 247.581 426.517 248.07C426.219 248.555 425.801 248.937 425.263 249.216C424.725 249.496 424.07 249.635 423.3 249.635C422.709 249.635 422.177 249.531 421.704 249.321C421.231 249.107 420.826 248.806 420.489 248.417C420.152 248.024 419.894 247.55 419.715 246.996C419.539 246.441 419.452 245.821 419.452 245.136V244.343C419.452 243.658 419.542 243.038 419.721 242.483C419.905 241.929 420.168 241.455 420.509 241.062C420.851 240.665 421.26 240.361 421.737 240.152C422.218 239.942 422.759 239.837 423.359 239.837C424.12 239.837 424.764 239.977 425.289 240.257C425.814 240.536 426.222 240.922 426.51 241.416C426.804 241.909 426.983 242.475 427.049 243.112H425.407C425.364 242.702 425.267 242.35 425.118 242.058C424.974 241.765 424.76 241.542 424.475 241.39C424.19 241.232 423.818 241.154 423.359 241.154C422.982 241.154 422.654 241.224 422.374 241.363C422.094 241.503 421.859 241.708 421.671 241.979C421.483 242.25 421.341 242.584 421.244 242.981C421.153 243.374 421.107 243.824 421.107 244.33V245.136C421.107 245.616 421.148 246.053 421.231 246.446C421.319 246.834 421.45 247.168 421.625 247.448C421.805 247.727 422.032 247.943 422.308 248.096C422.584 248.249 422.914 248.325 423.3 248.325C423.768 248.325 424.147 248.251 424.436 248.103C424.729 247.954 424.95 247.738 425.099 247.454C425.252 247.166 425.353 246.815 425.401 246.4Z" fill="white"/>
                <path d="M429.452 239.968L431.671 244.52L433.891 239.968H435.716L432.499 245.994V249.504H430.838V245.994L427.62 239.968H429.452Z" fill="white"/>
            </g>
        </svg>);
}
function IllustrationInLightTheme() {
    return (<svg className={illustrationStyles} viewBox="0 0 460 460" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M201.153 11.6366C272.586 -9.96488 333.275 69.2634 379.383 128.01C424.957 186.076 469.808 256.149 448.573 326.874C428.246 394.573 352.317 423.874 284.574 443.78C231.578 459.352 179.174 443.437 128.761 420.85C75.7014 397.076 2.18345 374.457 0.0361676 316.292C-2.07997 258.971 89.2472 254.046 120.836 206.194C161.495 144.603 130.568 32.9817 201.153 11.6366Z" fill="#DAE2E9" fillOpacity="0.5"/>
            <path d="M183.492 328.647L182.897 332.187L188.169 345.011L190.998 343.557L188.245 331.556L187.849 326.977L183.492 328.647Z" fill="#A0616A"/>
            <path d="M189.311 308.732C189.311 308.732 186.551 307.395 184.409 311.912C182.267 316.43 182.532 326.339 182.532 326.339C182.532 326.339 181.752 327.081 182.248 327.601C182.743 328.121 183.204 328.946 182.699 329.897C182.433 330.399 181.817 331.379 182.826 331.658C183.834 331.936 188.553 330.62 188.553 330.62C188.553 330.62 190.318 329.83 189.315 329.298C188.313 328.765 189.725 325.545 189.725 325.545L190.891 316.243L189.311 308.732Z" fill="#1A77CA"/>
            <path d="M191.478 349.911C192.586 349.57 193.02 347.782 192.447 345.916C191.874 344.05 190.511 342.813 189.403 343.154C188.296 343.494 187.862 345.282 188.435 347.148C189.008 349.014 190.37 350.251 191.478 349.911Z" fill="#A0616A"/>
            <path d="M217.605 404.871L220.247 404.162L218.769 393.634L214.87 394.68L217.605 404.871Z" fill="#A0616A"/>
            <path d="M226.641 402.382C226.761 402.499 226.924 402.934 226.967 403.095C227.1 403.59 226.806 404.1 226.311 404.233L218.129 406.428C217.791 406.519 217.444 406.318 217.353 405.98L217.262 405.64C217.262 405.64 216.582 404.725 217.077 403.239C217.077 403.239 218.378 403.949 219.51 401.986L219.745 401.037L223.697 402.567L225.588 402.301C226.002 402.243 226.342 402.09 226.641 402.382Z" fill="#111A23"/>
            <path d="M191.71 404.81L194.445 404.81L195.746 394.259L191.709 394.259L191.71 404.81Z" fill="#A0616A"/>
            <path d="M201.081 404.748C201.166 404.891 201.211 405.354 201.211 405.521C201.211 406.034 200.796 406.45 200.283 406.45H191.811C191.462 406.45 191.178 406.166 191.178 405.816V405.464C191.178 405.464 190.759 404.404 191.622 403.097C191.622 403.097 192.694 404.12 194.296 402.518L194.769 401.662L198.189 404.163L200.085 404.397C200.5 404.448 200.868 404.389 201.081 404.748Z" fill="#111A23"/>
            <path d="M209.914 341.959L217.107 372.964L220.579 398.016L215.122 398.761L199.992 356.098L196.52 398.761L190.319 399.257C190.319 399.257 185.358 348.657 187.59 342.456L209.914 341.959Z" fill="#111A23"/>
            <path d="M218.476 305.297L220.958 302.703L223.808 289.134L220.651 288.747L216.181 300.219L213.934 304.229L218.476 305.297Z" fill="#A0616A"/>
            <path d="M202.467 318.495C202.467 318.495 204.79 322.778 209.101 320.246C213.412 317.714 217.971 307.745 217.971 307.745C217.971 307.745 218.882 305.211 219.834 304.708C220.336 304.443 221.628 304.158 220.95 303.36C220.272 302.563 215.399 300.819 215.399 300.819C215.399 300.819 213.496 300.481 214.026 301.485C214.555 302.489 212.815 301.867 212.991 302.929C213.167 303.992 211.577 304.358 211.577 304.358L202.467 318.495Z" fill="#1A77CA"/>
            <path d="M224.79 287.218C225.365 285.352 224.934 283.564 223.826 283.222C222.719 282.881 221.355 284.116 220.78 285.981C220.205 287.846 220.636 289.635 221.744 289.977C222.851 290.318 224.215 289.083 224.79 287.218Z" fill="#A0616A"/>
            <path d="M202.774 303.656H195.983L195.708 305.798C195.708 305.798 187.143 308.153 187.357 309.438C187.572 310.723 188.856 332.135 188.856 332.135L187.786 342.841C187.786 342.841 207.057 350.978 210.054 341.342L208.556 330.208C208.556 330.208 212.196 311.151 211.339 310.294C210.483 309.438 203.417 306.012 203.417 306.012L202.774 303.656Z" fill="#1A77CA"/>
            <path d="M198.83 303.683C202.196 303.683 204.926 300.954 204.926 297.588C204.926 294.221 202.196 291.492 198.83 291.492C195.463 291.492 192.734 294.221 192.734 297.588C192.734 300.954 195.463 303.683 198.83 303.683Z" fill="#A0616A"/>
            <path d="M203.344 295.08C204.123 295.014 204.902 294.949 205.68 294.883C205.413 294.76 205.134 294.629 204.955 294.394C204.777 294.16 204.74 293.789 204.956 293.589C205.207 293.354 205.624 293.478 205.9 293.685C205.748 292.894 205.276 292.169 204.613 291.711C203.804 291.151 201.802 291.818 200.827 291.69C199.701 291.542 199.561 290.582 198.435 290.434C198.011 290.378 197.533 290.336 197.2 290.604C196.83 290.901 196.788 291.463 196.462 291.807C195.996 292.299 195.197 292.154 194.533 292.293C193.594 292.489 192.885 293.312 192.558 294.215C192.231 295.117 192.219 296.099 192.211 297.058C192.202 298.134 192.198 299.379 192.976 300.122C193.45 300.575 194.741 302.128 194.881 302.768C194.98 303.223 199.019 303.777 199.895 303.751L202.879 302.768C203.172 301.476 202.687 298.922 201.99 297.796C202.219 298.099 203.281 299.012 203.444 298.668C203.606 298.324 203.532 297.246 203.734 296.924C203.989 296.517 202.866 295.121 203.344 295.08Z" fill="#111A23"/>
            <mask id="mask0_1491_14039" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="60" y="217" width="126" height="74">
                <rect x="60.1152" y="217.754" width="125.519" height="72.4865" rx="2" fill="#102438"/>
            </mask>
            <g mask="url(#mask0_1491_14039)">
                <rect x="60" y="217.809" width="125.813" height="72.2294" fill="white"/>
                <path d="M59.1455 278.516C65.3795 277.864 70.8254 263.111 81.7499 263.11C92.6744 263.11 94.219 240.758 104.629 240.758C115.039 240.758 117.01 252.059 126.273 252.059C135.535 252.059 135.702 247.14 145.639 247.14C155.577 247.14 157.998 256.855 166.673 256.855C175.349 256.855 178.5 251.628 185.621 250.883" stroke="#1A77CA"/>
                <path d="M59.1455 290.916C65.3795 290.264 70.8248 282.809 81.7493 282.808C92.6738 282.808 95.4562 233.391 105.866 233.391C116.276 233.391 117.01 264.459 126.273 264.459C135.535 264.459 135.299 248.612 145.236 248.612C155.173 248.612 157.998 269.255 166.673 269.255C175.349 269.255 178.5 264.028 185.621 263.283" stroke="#1A77CA" strokeOpacity="0.2"/>
            </g>
            <mask id="mask1_1491_14039" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="98" y="146" width="88" height="50">
                <rect x="98" y="146" width="88" height="50" rx="2" fill="#102438"/>
            </mask>
            <g mask="url(#mask1_1491_14039)">
                <rect x="98" y="146.535" width="87.8696" height="49.4905" rx="2" fill="white"/>
                <path d="M97.3535 187.706C101.628 187.255 105.417 181.498 112.905 181.455C120.393 181.412 121.114 172.123 128.249 172.082C135.384 172.042 136.714 187.481 143.063 187.444C149.412 187.408 156.228 184.834 163.039 184.795C169.85 184.756 171.648 187.28 177.594 187.246C183.541 187.212 181.117 188.516 186 188" stroke="#8C5FC7" strokeWidth="0.5"/>
                <path d="M97.4033 192.131L97.58 192.113C101.87 191.673 106.009 191.248 113.536 191.248C121.165 191.248 122.482 188.363 129.752 188.363C137.023 188.363 137.817 174.004 144.286 174.004C150.755 174.004 150.808 181.075 157.749 181.075C164.689 181.075 166.443 177.29 172.502 177.29C178.561 177.29 180.771 181.586 185.745 181.075" stroke="#C5AEEE" strokeWidth="0.5"/>
            </g>
            <path d="M134.149 98.9537C134.471 98.0831 135.703 98.0831 136.025 98.9537L137.776 103.685C137.877 103.959 138.093 104.175 138.366 104.276L143.098 106.027C143.969 106.349 143.969 107.581 143.098 107.903L138.366 109.654C138.093 109.755 137.877 109.971 137.776 110.245L136.025 114.976C135.703 115.847 134.471 115.847 134.149 114.976L132.398 110.245C132.297 109.971 132.081 109.755 131.807 109.654L127.076 107.903C126.205 107.581 126.205 106.349 127.076 106.027L131.807 104.276C132.081 104.175 132.297 103.959 132.398 103.685L134.149 98.9537Z" fill="#449BE1"/>
            <path d="M161.268 76.8576C161.59 75.987 162.821 75.987 163.143 76.8576L165.708 83.7887C165.809 84.0624 166.025 84.2782 166.299 84.3795L173.23 86.9442C174.101 87.2664 174.101 88.4978 173.23 88.8199L166.299 91.3846C166.025 91.4859 165.809 91.7017 165.708 91.9755L163.143 98.9065C162.821 99.7771 161.59 99.7771 161.268 98.9065L158.703 91.9755C158.602 91.7017 158.386 91.4859 158.112 91.3846L151.181 88.8199C150.31 88.4978 150.31 87.2664 151.181 86.9442L158.112 84.3795C158.386 84.2782 158.602 84.0624 158.703 83.7887L161.268 76.8576Z" fill="#155EA0"/>
            <path d="M345.546 391.177C345.224 392.048 343.992 392.048 343.67 391.177L340.427 382.413C340.326 382.14 340.11 381.924 339.836 381.823L331.073 378.58C330.202 378.258 330.202 377.026 331.073 376.704L339.836 373.461C340.11 373.36 340.326 373.144 340.427 372.87L343.67 364.107C343.992 363.236 345.224 363.236 345.546 364.107L348.789 372.87C348.89 373.144 349.106 373.36 349.379 373.461L358.143 376.704C359.014 377.026 359.014 378.258 358.143 378.58L349.379 381.823C349.106 381.924 348.89 382.14 348.789 382.413L345.546 391.177Z" fill="#449BE1"/>
            <path d="M304.256 425.007C303.934 425.878 302.703 425.878 302.381 425.007L297.781 412.578C297.68 412.304 297.464 412.088 297.191 411.987L284.761 407.388C283.891 407.065 283.891 405.834 284.761 405.512L297.191 400.913C297.464 400.811 297.68 400.596 297.781 400.322L302.381 387.893C302.703 387.022 303.934 387.022 304.256 387.893L308.856 400.322C308.957 400.596 309.173 400.811 309.446 400.913L321.876 405.512C322.746 405.834 322.746 407.065 321.876 407.388L309.446 411.987C309.173 412.088 308.957 412.304 308.856 412.578L304.256 425.007Z" fill="#155EA0"/>
            <mask id="mask2_1491_14039" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="207" y="125" width="247" height="142">
                <rect x="207.904" y="125.546" width="246.07" height="140.611" rx="4" fill="#102438"/>
            </mask>
            <g mask="url(#mask2_1491_14039)">
                <rect x="207.599" y="126.118" width="246.647" height="140.113" rx="4" fill="white"/>
                <path d="M205.181 186.317C217.402 185.052 225.053 161.398 243.237 161.399C261.422 161.4 261.625 179.013 282.838 180.745C304.052 182.477 310.214 199.957 327.439 200.295C344.664 200.633 349.513 180.745 371.911 180.745C394.308 180.745 399.374 166.056 412.552 165.443C425.73 164.83 441.25 171.028 455.211 169.583" stroke="#5ECD9E" strokeWidth="2"/>
                <path d="M205.812 242.767C226.813 242.767 226.285 214.384 247.683 214.385C269.08 214.387 264.454 162.497 285.668 164.229C306.881 165.962 310.468 189.065 327.693 189.403C344.918 189.741 352.011 200.565 374.408 200.565C396.806 200.565 401.871 185.876 415.049 185.263C428.227 184.65 443.748 190.848 457.708 189.403" stroke="#5ECD9E" strokeOpacity="0.2" strokeWidth="2"/>
                <path d="M280.069 250.282H277.993L278.006 248.96H280.069C280.669 248.96 281.172 248.829 281.577 248.568C281.987 248.306 282.294 247.932 282.498 247.445C282.708 246.957 282.812 246.377 282.812 245.704V245.179C282.812 244.656 282.752 244.193 282.632 243.79C282.516 243.387 282.343 243.048 282.111 242.774C281.884 242.499 281.604 242.291 281.27 242.149C280.941 242.007 280.56 241.936 280.129 241.936H277.953V240.608H280.129C280.774 240.608 281.364 240.716 281.898 240.933C282.432 241.146 282.892 241.454 283.279 241.857C283.671 242.26 283.971 242.743 284.181 243.305C284.39 243.868 284.494 244.497 284.494 245.192V245.704C284.494 246.399 284.39 247.028 284.181 247.591C283.971 248.153 283.671 248.636 283.279 249.039C282.888 249.438 282.421 249.746 281.878 249.963C281.339 250.175 280.736 250.282 280.069 250.282ZM278.921 240.608V250.282H277.245V240.608H278.921Z" fill="#1F303F"/>
                <path d="M292.538 248.96V250.282H287.378V248.96H292.538ZM287.852 240.608V250.282H286.176V240.608H287.852ZM291.864 244.647V245.95H287.378V244.647H291.864ZM292.504 240.608V241.936H287.378V240.608H292.504Z" fill="#1F303F"/>
                <path d="M297.597 246.674H295.068V245.352H297.597C298.038 245.352 298.394 245.281 298.665 245.139C298.937 244.997 299.135 244.802 299.26 244.554C299.389 244.302 299.453 244.014 299.453 243.691C299.453 243.385 299.389 243.099 299.26 242.833C299.135 242.563 298.937 242.346 298.665 242.182C298.394 242.018 298.038 241.936 297.597 241.936H295.582V250.282H293.906V240.608H297.597C298.349 240.608 298.988 240.741 299.513 241.006C300.043 241.268 300.445 241.631 300.721 242.096C300.997 242.557 301.135 243.084 301.135 243.677C301.135 244.302 300.997 244.838 300.721 245.285C300.445 245.733 300.043 246.076 299.513 246.315C298.988 246.554 298.349 246.674 297.597 246.674Z" fill="#1F303F"/>
                <path d="M308.725 248.96V250.282H303.845V248.96H308.725ZM304.313 240.608V250.282H302.637V240.608H304.313Z" fill="#1F303F"/>
                <path d="M317.329 245.179V245.71C317.329 246.441 317.233 247.097 317.042 247.677C316.851 248.257 316.577 248.751 316.221 249.159C315.869 249.566 315.447 249.879 314.953 250.096C314.459 250.308 313.911 250.415 313.311 250.415C312.714 250.415 312.169 250.308 311.675 250.096C311.186 249.879 310.761 249.566 310.4 249.159C310.04 248.751 309.759 248.257 309.559 247.677C309.363 247.097 309.265 246.441 309.265 245.71V245.179C309.265 244.448 309.363 243.795 309.559 243.219C309.755 242.639 310.031 242.145 310.387 241.737C310.747 241.325 311.172 241.013 311.662 240.8C312.156 240.583 312.701 240.475 313.297 240.475C313.898 240.475 314.445 240.583 314.939 240.8C315.433 241.013 315.858 241.325 316.214 241.737C316.57 242.145 316.844 242.639 317.035 243.219C317.231 243.795 317.329 244.448 317.329 245.179ZM315.653 245.71V245.166C315.653 244.625 315.6 244.149 315.493 243.737C315.391 243.321 315.237 242.973 315.033 242.694C314.832 242.41 314.585 242.198 314.292 242.056C313.998 241.91 313.667 241.837 313.297 241.837C312.928 241.837 312.599 241.91 312.309 242.056C312.02 242.198 311.773 242.41 311.568 242.694C311.368 242.973 311.215 243.321 311.108 243.737C311.001 244.149 310.948 244.625 310.948 245.166V245.71C310.948 246.251 311.001 246.729 311.108 247.146C311.215 247.562 311.37 247.914 311.575 248.202C311.784 248.486 312.033 248.7 312.323 248.847C312.612 248.988 312.941 249.059 313.311 249.059C313.684 249.059 314.016 248.988 314.305 248.847C314.594 248.7 314.839 248.486 315.039 248.202C315.24 247.914 315.391 247.562 315.493 247.146C315.6 246.729 315.653 246.251 315.653 245.71Z" fill="#1F303F"/>
                <path d="M319.639 240.608L321.895 245.225L324.151 240.608H326.007L322.736 246.72V250.282H321.047V246.72L317.776 240.608H319.639Z" fill="#1F303F"/>
                <path d="M327.749 240.608H329.244L332.054 248.069L334.858 240.608H336.353L332.642 250.282H331.453L327.749 240.608ZM327.068 240.608H328.49L328.737 247.066V250.282H327.068V240.608ZM335.612 240.608H337.04V250.282H335.365V247.066L335.612 240.608Z" fill="#1F303F"/>
                <path d="M345.391 248.96V250.282H340.231V248.96H345.391ZM340.705 240.608V250.282H339.03V240.608H340.705ZM344.717 244.647V245.95H340.231V244.647H344.717ZM345.358 240.608V241.936H340.231V240.608H345.358Z" fill="#1F303F"/>
                <path d="M354.469 240.608V250.282H352.794L348.435 243.352V250.282H346.759V240.608H348.435L352.807 247.551V240.608H354.469Z" fill="#1F303F"/>
                <path d="M360.263 240.608V250.282H358.601V240.608H360.263ZM363.313 240.608V241.936H355.577V240.608H363.313Z" fill="#1F303F"/>
                <path d="M369.354 240.608V250.282H367.679V240.608H369.354ZM373.319 244.84V246.162H368.927V244.84H373.319ZM373.873 240.608V241.936H368.927V240.608H373.873Z" fill="#1F303F"/>
                <path d="M375.208 240.608H378.646C379.385 240.608 380.015 240.718 380.535 240.94C381.056 241.161 381.454 241.489 381.73 241.923C382.01 242.353 382.151 242.884 382.151 243.518C382.151 244.001 382.062 244.426 381.884 244.794C381.706 245.161 381.454 245.471 381.129 245.724C380.804 245.972 380.417 246.164 379.968 246.302L379.461 246.548H376.37L376.357 245.225H378.673C379.073 245.225 379.407 245.155 379.674 245.013C379.941 244.871 380.141 244.678 380.275 244.435C380.413 244.187 380.482 243.908 380.482 243.598C380.482 243.261 380.415 242.969 380.282 242.721C380.152 242.468 379.952 242.275 379.681 242.142C379.409 242.005 379.064 241.936 378.646 241.936H376.884V250.282H375.208V240.608ZM380.755 250.282L378.473 245.936L380.228 245.93L382.544 250.195V250.282H380.755Z" fill="#1F303F"/>
                <path d="M390.181 248.96V250.282H385.021V248.96H390.181ZM385.495 240.608V250.282H383.819V240.608H385.495ZM389.506 244.647V245.95H385.021V244.647H389.506ZM390.147 240.608V241.936H385.021V240.608H390.147Z" fill="#1F303F"/>
                <path d="M396.696 248.993L399.252 251.013L398.164 251.963L395.654 249.963L396.696 248.993ZM399.272 245.179V245.71C399.272 246.441 399.176 247.097 398.985 247.677C398.794 248.257 398.52 248.751 398.164 249.159C397.808 249.566 397.383 249.879 396.889 250.096C396.395 250.308 395.848 250.415 395.247 250.415C394.651 250.415 394.106 250.308 393.612 250.096C393.122 249.879 392.697 249.566 392.337 249.159C391.976 248.751 391.696 248.257 391.496 247.677C391.3 247.097 391.202 246.441 391.202 245.71V245.179C391.202 244.448 391.3 243.795 391.496 243.219C391.691 242.639 391.967 242.145 392.323 241.737C392.684 241.325 393.109 241.013 393.598 240.8C394.092 240.583 394.637 240.475 395.234 240.475C395.834 240.475 396.382 240.583 396.876 240.8C397.374 241.013 397.801 241.325 398.157 241.737C398.513 242.145 398.787 242.639 398.978 243.219C399.174 243.795 399.272 244.448 399.272 245.179ZM397.59 245.71V245.166C397.59 244.625 397.537 244.149 397.43 243.737C397.327 243.321 397.174 242.973 396.969 242.694C396.769 242.41 396.522 242.198 396.228 242.056C395.939 241.91 395.608 241.837 395.234 241.837C394.869 241.837 394.542 241.91 394.252 242.056C393.963 242.198 393.716 242.41 393.512 242.694C393.307 242.973 393.151 243.321 393.044 243.737C392.937 244.149 392.884 244.625 392.884 245.166V245.71C392.884 246.251 392.937 246.729 393.044 247.146C393.151 247.562 393.307 247.914 393.512 248.202C393.721 248.486 393.97 248.7 394.259 248.847C394.553 248.988 394.882 249.059 395.247 249.059C395.621 249.059 395.952 248.988 396.242 248.847C396.531 248.7 396.776 248.486 396.976 248.202C397.176 247.914 397.327 247.562 397.43 247.146C397.537 246.729 397.59 246.251 397.59 245.71Z" fill="#1F303F"/>
                <path d="M406.428 240.608H408.097V247.073C408.097 247.808 407.936 248.424 407.616 248.92C407.296 249.416 406.859 249.79 406.308 250.043C405.76 250.291 405.148 250.415 404.472 250.415C403.773 250.415 403.15 250.291 402.603 250.043C402.056 249.79 401.624 249.416 401.308 248.92C400.997 248.424 400.841 247.808 400.841 247.073V240.608H402.51V247.073C402.51 247.538 402.59 247.921 402.75 248.222C402.91 248.519 403.137 248.738 403.431 248.88C403.724 249.022 404.071 249.092 404.472 249.092C404.873 249.092 405.217 249.022 405.507 248.88C405.8 248.738 406.027 248.519 406.187 248.222C406.348 247.921 406.428 247.538 406.428 247.073V240.608Z" fill="#1F303F"/>
                <path d="M416.28 248.96V250.282H411.12V248.96H416.28ZM411.594 240.608V250.282H409.919V240.608H411.594ZM415.606 244.647V245.95H411.12V244.647H415.606ZM416.247 240.608V241.936H411.12V240.608H416.247Z" fill="#1F303F"/>
                <path d="M425.358 240.608V250.282H423.683L419.324 243.352V250.282H417.649V240.608H419.324L423.696 247.551V240.608H425.358Z" fill="#1F303F"/>
                <path d="M433.095 247.132H434.763C434.71 247.766 434.532 248.331 434.229 248.827C433.927 249.318 433.502 249.706 432.955 249.989C432.407 250.273 431.742 250.415 430.959 250.415C430.358 250.415 429.817 250.308 429.337 250.096C428.856 249.879 428.444 249.573 428.102 249.179C427.759 248.78 427.497 248.3 427.314 247.737C427.136 247.174 427.047 246.545 427.047 245.85V245.046C427.047 244.351 427.138 243.722 427.321 243.159C427.508 242.596 427.775 242.116 428.122 241.717C428.469 241.314 428.885 241.006 429.37 240.794C429.86 240.581 430.409 240.475 431.019 240.475C431.793 240.475 432.447 240.616 432.981 240.9C433.515 241.183 433.929 241.575 434.223 242.076C434.521 242.577 434.703 243.15 434.77 243.797H433.101C433.057 243.381 432.959 243.024 432.808 242.727C432.661 242.43 432.443 242.204 432.154 242.049C431.864 241.89 431.486 241.81 431.019 241.81C430.636 241.81 430.302 241.881 430.018 242.023C429.733 242.165 429.495 242.373 429.303 242.647C429.112 242.922 428.967 243.261 428.869 243.664C428.776 244.063 428.729 244.519 428.729 245.033V245.85C428.729 246.337 428.771 246.78 428.856 247.179C428.945 247.573 429.079 247.912 429.257 248.195C429.439 248.479 429.67 248.698 429.951 248.853C430.231 249.008 430.567 249.086 430.959 249.086C431.435 249.086 431.82 249.01 432.113 248.86C432.412 248.709 432.636 248.49 432.788 248.202C432.943 247.91 433.046 247.553 433.095 247.132Z" fill="#1F303F"/>
                <path d="M437.213 240.608L439.469 245.225L441.726 240.608H443.581L440.31 246.72V250.282H438.622V246.72L435.351 240.608H437.213Z" fill="#1F303F"/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M251.439 302.321C249.306 301.444 247.804 299.345 247.804 296.895C247.804 293.656 250.429 291.03 253.669 291.03C256.908 291.03 259.533 293.656 259.533 296.895C259.533 299.357 258.017 301.464 255.867 302.334L256.121 305.341H251.084L251.439 302.321Z" fill="#FFDACA"/>
            <path d="M248.861 390.192L246.23 390.191L244.978 380.042L248.861 380.043L248.861 390.192Z" fill="#FFDACA"/>
            <path d="M239.846 390.132C239.764 390.27 239.721 390.715 239.721 390.876C239.721 391.369 240.121 391.769 240.614 391.769H248.763C249.099 391.769 249.372 391.496 249.372 391.159V390.82C249.372 390.82 249.775 389.801 248.945 388.544C248.945 388.544 247.914 389.528 246.372 387.987L245.918 387.163L242.628 389.569L240.804 389.794C240.405 389.843 240.051 389.786 239.846 390.132Z" fill="#041A2D"/>
            <path d="M258.844 390.192L256.213 390.191L254.961 380.042L258.845 380.043L258.844 390.192Z" fill="#FFDACA"/>
            <path d="M249.83 390.132C249.748 390.27 249.704 390.715 249.704 390.876C249.704 391.369 250.104 391.769 250.597 391.769H258.746C259.082 391.769 259.355 391.496 259.355 391.16V390.82C259.355 390.82 259.758 389.801 258.928 388.544C258.928 388.544 257.897 389.528 256.356 387.987L255.901 387.163L252.611 389.57L250.787 389.794C250.388 389.843 250.035 389.786 249.83 390.132Z" fill="#041A2D"/>
            <path d="M242.661 335.661C242.661 335.661 242.304 338.766 241.965 342.521C241.626 346.276 244.822 384.486 244.822 384.486L250.036 384.819L252.144 348.321L254.141 384.93L260.353 384.264L261.827 337.79L242.661 335.661Z" fill="#041A2D"/>
            <path d="M257.048 305.61L250.37 304.39L249.021 305.738L242.16 308.605L242.8 329.04C242.8 329.04 241.668 331.223 242.16 333.639C242.633 335.953 242.368 338.339 242.368 338.339L261.828 338.114L261.02 325.911L263.238 310.38L258.054 307.926L257.048 305.61Z" fill="#A9BBCB"/>
            <path d="M252.206 330.684C254.104 331.137 255.875 330.525 256.163 329.317C256.451 328.11 255.147 326.764 253.249 326.311C252.493 326.12 251.703 326.113 250.944 326.289L242.881 324.472L242.108 328.274L250.139 329.662C250.737 330.163 251.446 330.513 252.206 330.684Z" fill="#FFDACA"/>
            <path d="M243.491 309.715L242.16 308.605C242.16 308.605 239.497 309.936 239.054 311.046C238.61 312.155 235.947 322.805 235.947 322.805C235.947 322.805 235.425 328.642 236.796 329.384C238.166 330.127 246.819 329.683 246.819 329.683L247.485 325.246L243.935 323.915L243.491 309.715Z" fill="#A9BBCB"/>
            <path d="M257.876 303.102C258.578 303.164 259.725 301.367 259.674 299.626C259.641 298.507 260.613 298.286 260.686 296.842C260.728 296.001 259.435 295.738 259.346 295.063C259.185 293.841 258.396 293.018 258.298 292.918C257.448 292.054 256.848 292.335 255.848 291.334C255.246 290.732 255.4 290.566 254.995 290.299C253.825 289.528 251.722 290.364 250.465 291.49C250.03 291.88 248.408 291.454 247.7 292.554C246.865 293.85 247.059 296.494 247.417 296.843C247.634 297.055 248.131 296.537 249.477 296.205C250.723 295.897 249.547 297.419 250.988 297.164C252.691 296.862 253.025 296.343 253.663 296.654C254.337 296.983 254.864 298 254.711 298.8C254.635 299.197 254.415 299.418 254.539 299.62C254.766 299.989 255.026 299.627 255.316 299.997C255.654 300.427 254.814 301.53 254.972 301.637C255.004 301.659 255.101 301.656 256.007 300.784C256.369 300.436 256.475 300.222 256.546 300.255C256.661 300.308 257.412 301.008 257.125 301.561C256.679 302.421 257.25 302.448 257.125 303.027C256.996 303.624 257.429 303.062 257.876 303.102Z" fill="#041A2D"/>
            <path d="M256.6 322.871L257.062 323.245L254.64 326.864L254.179 326.739L256.6 322.871Z" fill="#111A23"/>
            <path d="M256.155 331.597C254.426 332.499 253.49 334.123 254.065 335.223C254.639 336.324 256.507 336.484 258.236 335.581C258.932 335.229 259.534 334.717 259.991 334.085L267.269 330.169L265.358 326.792L258.386 331.012C257.606 331.026 256.842 331.226 256.155 331.597Z" fill="#FFDACA"/>
            <path d="M261.906 310.602C261.906 310.602 263.903 309.271 265.234 312.821C266.284 315.622 268.232 323.877 269.008 327.237C269.214 328.129 268.916 329.061 268.232 329.669L267.342 330.46L263.016 333.677L260.066 329.384L263.648 327.224L259.562 318.361L261.906 310.602Z" fill="#A9BBCB"/>
            <path d="M246.671 328.229L247.165 326.845L256.362 328.427L264.173 324.472L264.569 325.56L256.065 330.603L246.671 328.229Z" fill="#E6E6E6"/>
            <path d="M264.965 324.867L256.165 329.317L246.105 327.864L247.067 332.086L255.571 333.569L263.611 329.717L264.965 324.867Z" fill="#111A23"/>
        </svg>);
}
const illustrationStyles = css({
    width: "100%",
});
