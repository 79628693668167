import { autoUpdate, computePosition, offset } from "@floating-ui/react-dom";
import type { AriaAttributes, CSSProperties } from "react";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useFocusTrap } from "../../hooks/useFocusTrap";
import { isHTMLElement } from "../../utils/isHTMLElement";
interface UseDropdownButtonOptions {
    trapFocus: boolean;
    dropdownAriaRole: DropdownAriaRole;
}
export function useDropdownButton(options: UseDropdownButtonOptions): DropdownButtonState {
    const [isOpen, setIsOpen] = React.useState(false);
    return useOpenControlledDropdownButton({ ...options, isOpen, setIsOpen });
}
interface UseOpenControlledDropdownButtonOptions extends UseDropdownButtonOptions {
    isOpen: boolean;
    setIsOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
}
export function useOpenControlledDropdownButton({ trapFocus, dropdownAriaRole, setIsOpen, isOpen }: UseOpenControlledDropdownButtonOptions): DropdownButtonState {
    const id = React.useRef(`dropdown-${uuidv4()}`);
    const [buttonElement, setButtonElement] = React.useState<HTMLButtonElement | null>(null);
    const [dropdownElement, setDropdownElement] = React.useState<HTMLDivElement | null>(null);
    const [dropdownPosition, setDropdownPosition] = React.useState({ x: 0, y: 0 });
    const setFocusTrapElement = useFocusTrap();
    const onCloseSubscribers = React.useRef<CloseDropdownCallback[]>([]);
    const onOpenSubscribers = React.useRef<OpenDropdownCallback[]>([]);
    const subscribeClose = React.useCallback((subscriber: CloseDropdownCallback) => {
        onCloseSubscribers.current.push(subscriber);
        return () => (onCloseSubscribers.current = onCloseSubscribers.current.filter((prev) => prev !== subscriber));
    }, []);
    const subscribeOpen = React.useCallback((subscriber: OpenDropdownCallback) => {
        onOpenSubscribers.current.push(subscriber);
        return () => {
            onOpenSubscribers.current = onOpenSubscribers.current.filter((prev) => prev !== subscriber);
        };
    }, []);
    const closeDropdown = React.useCallback(() => {
        onCloseSubscribers.current.forEach((x) => x());
        setIsOpen(false);
    }, [setIsOpen]);
    const toggleDropdown = React.useCallback(() => {
        setIsOpen((prev) => {
            const next = !prev;
            next ? onOpenSubscribers.current.forEach((x) => x()) : onCloseSubscribers.current.forEach((x) => x());
            return next;
        });
    }, [setIsOpen]);
    React.useLayoutEffect(() => {
        // autoUpdate is expensive so we shouldn't run it unless both elements are mounted and visible
        if (!isOpen || buttonElement === null || dropdownElement === null)
            return;
        return autoUpdate(buttonElement, dropdownElement, async () => {
            const position = await computePosition(buttonElement, dropdownElement, {
                placement: "bottom-start",
                middleware: [offset(8)],
            });
            setDropdownPosition(position);
        });
    }, [buttonElement, dropdownElement, isOpen]);
    React.useEffect(() => {
        if (!isOpen || buttonElement === null || dropdownElement === null)
            return;
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                closeDropdown();
                buttonElement?.focus();
                event.preventDefault();
            }
        };
        const onFocusInOrPointerDown = (event: FocusEvent | PointerEvent) => {
            if (isHTMLElement(event.target) && !buttonElement.contains(event.target) && !dropdownElement.contains(event.target)) {
                closeDropdown();
            }
        };
        window.addEventListener("keydown", onKeyDown);
        window.addEventListener("focusin", onFocusInOrPointerDown);
        window.addEventListener("pointerdown", onFocusInOrPointerDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
            window.removeEventListener("focusin", onFocusInOrPointerDown);
            window.removeEventListener("pointerdown", onFocusInOrPointerDown);
        };
    }, [isOpen, buttonElement, dropdownElement, closeDropdown]);
    const buttonAriaAttributes: ButtonAriaAttributes = {
        "aria-expanded": isOpen,
        "aria-haspopup": dropdownAriaRole,
        "aria-controls": isOpen ? id.current : undefined,
    };
    const dropdownAttributes: DropdownAttributes = {
        dropdownId: id.current,
        dropdownPositionStyles: {
            position: "absolute",
            top: dropdownPosition.y,
            left: dropdownPosition.x,
        },
        dropdownAriaAttributes: {
            role: dropdownAriaRole,
        },
    };
    return {
        isOpen,
        closeDropdown,
        toggleDropdown,
        setButtonRef: setButtonElement,
        buttonAriaAttributes,
        setDropdownRef: (dropdown: HTMLDivElement) => {
            setDropdownElement(dropdown);
            if (trapFocus) {
                setFocusTrapElement(dropdown);
            }
        },
        dropdownAttributes,
        subscribeOpen,
        subscribeClose,
    };
}
export interface DropdownButtonState {
    isOpen: boolean;
    toggleDropdown: () => void;
    closeDropdown: () => void;
    setButtonRef: (button: HTMLButtonElement) => void;
    buttonAriaAttributes: ButtonAriaAttributes;
    setDropdownRef: (dropdown: HTMLDivElement) => void;
    dropdownAttributes: DropdownAttributes;
    subscribeOpen: (subscriber: OpenDropdownCallback) => CleanupSubscription;
    subscribeClose: (subscriber: CloseDropdownCallback) => CleanupSubscription;
}
interface ButtonAriaAttributes {
    "aria-expanded": boolean;
    "aria-haspopup": DropdownAriaRole;
    "aria-controls": string | undefined;
}
interface DropdownAttributes {
    dropdownId: string;
    dropdownPositionStyles: {
        position: CSSProperties["position"];
        left: CSSProperties["left"];
        top: CSSProperties["top"];
    };
    dropdownAriaAttributes: {
        role: DropdownAriaRole;
    };
}
type DropdownAriaRole = Exclude<AriaAttributes["aria-haspopup"], boolean | undefined>;
type CloseDropdownCallback = () => void;
type OpenDropdownCallback = () => void;
type CleanupSubscription = () => void;
