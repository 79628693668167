import { css } from "@emotion/css";
import React from "react";
import { useDesignSystemTheme } from "../../Theme";
export function EmptyStateDefaultIllustration() {
    const currentTheme = useDesignSystemTheme();
    return currentTheme === "light" ? <IllustrationInLightTheme /> : <IllustrationInDarkTheme />;
}
function IllustrationInDarkTheme() {
    return (<svg className={illustrationStyles} viewBox="0 0 260 260" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M113.695 6.57719C154.071 -5.63234 188.373 39.1488 214.434 72.3534C240.193 105.174 265.544 144.78 253.541 184.755C242.052 223.02 199.136 239.581 160.846 250.832C130.892 259.634 101.272 250.639 72.7777 237.871C42.7877 224.434 1.23413 211.65 0.0204426 178.774C-1.17564 146.375 50.4441 143.591 68.2987 116.545C91.2799 81.7323 73.7991 18.6418 113.695 6.57719Z" fill="#2E475D"/>
            <path d="M99.6216 161.727C105.197 158.113 111.753 151.906 109.267 144.597C107.91 140.616 106.029 137.208 105.778 132.905C105.568 129.261 106.112 125.612 107.375 122.189C112.38 108.673 127.304 102.271 140.701 106.344C153.151 110.107 161.725 124.702 156.56 137.323C153.585 144.62 152.251 150.357 158.886 155.99C160.688 157.539 165.066 159.845 165.043 162.587C165.043 166.167 158.031 161.819 157.256 161.199C158.145 162.759 166.958 172.007 161.349 172.673C156.184 173.281 151.623 166.029 148.522 162.909C143.312 157.677 144.224 169.254 144.201 171.64C144.201 175.415 141.51 183.114 136.756 178.088C132.834 173.969 134.316 167.418 131.58 162.851C128.593 157.837 123.599 167.854 122.379 169.735C120.988 171.789 114.033 181.691 111.274 176.413C108.994 172.133 112.619 165.41 114.375 161.497C113.737 162.897 109.176 164.939 107.842 165.662C104.958 167.259 101.689 168.018 98.4017 167.854C91.4012 167.337 96.7713 163.597 99.6216 161.75V161.727Z" fill="#0D80D8"/>
        </svg>);
}
function IllustrationInLightTheme() {
    return (<svg className={illustrationStyles} viewBox="0 0 260 260" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M113.695 6.57719C154.071 -5.63234 188.373 39.1488 214.434 72.3534C240.193 105.174 265.544 144.78 253.541 184.755C242.052 223.02 199.136 239.581 160.846 250.832C130.892 259.634 101.272 250.639 72.7777 237.871C42.7877 224.434 1.23413 211.65 0.0204426 178.774C-1.17564 146.375 50.4441 143.591 68.2987 116.545C91.2799 81.7323 73.7991 18.6418 113.695 6.57719Z" fill="#F2F8FD"/>
            <path d="M99.6221 161.727C105.197 158.113 111.753 151.906 109.268 144.597C107.911 140.616 106.03 137.208 105.779 132.905C105.569 129.261 106.112 125.612 107.375 122.189C112.38 108.673 127.305 102.271 140.701 106.344C153.152 110.107 161.726 124.702 156.561 137.323C153.585 144.62 152.251 150.357 158.887 155.99C160.688 157.539 165.066 159.845 165.043 162.587C165.043 166.167 158.032 161.819 157.256 161.199C158.146 162.759 166.959 172.007 161.349 172.673C156.185 173.281 151.624 166.029 148.523 162.909C143.312 157.677 144.224 169.254 144.202 171.64C144.202 175.415 141.511 183.114 136.757 178.088C132.834 173.969 134.317 167.418 131.58 162.851C128.593 157.837 123.599 167.854 122.379 169.735C120.988 171.789 114.034 181.691 111.274 176.413C108.994 172.133 112.62 165.41 114.376 161.497C113.737 162.897 109.176 164.939 107.843 165.662C104.958 167.259 101.69 168.018 98.4022 167.854C91.4017 167.337 96.7718 163.597 99.6221 161.75V161.727Z" fill="#0D80D8"/>
        </svg>);
}
const illustrationStyles = css({
    width: "100%",
});
