import { css } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
export function CircleCheckIcon() {
    return (<svg className={styles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 36C14.25 36 9 33 6.125 28C3.25 23.0625 3.25 17 6.125 12C9 7.0625 14.25 4 20 4C25.6875 4 30.9375 7.0625 33.8125 12C36.6875 17 36.6875 23.0625 33.8125 28C30.9375 33 25.6875 36 20 36ZM27.0625 17.0625H27C27.625 16.5 27.625 15.5625 27 14.9375C26.4375 14.375 25.5 14.375 24.9375 14.9375L18 21.9375L15.0625 19C14.4375 18.375 13.5 18.375 12.9375 19C12.3125 19.5625 12.3125 20.5 12.9375 21.0625L16.9375 25.0625C17.5 25.6875 18.4375 25.6875 19.0625 25.0625L27.0625 17.0625Z"/>
        </svg>);
}
const styles = css(iconStyles, {
    width: 20,
    height: 20,
});
