import { css } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
export function CopyToClipboardIcon({ variant = "solid" }: {
    variant?: "solid" | "outline";
}) {
    return (<svg className={styles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 32C4 34.25 5.75 36 8 36H22C24.1875 36 26 34.25 26 32V28H18C14.6875 28 12 25.3125 12 22V14H8C5.75 14 4 15.8125 4 18V32ZM18 26H32C34.1875 26 36 24.25 36 22V8C36 5.8125 34.1875 4 32 4H18C15.75 4 14 5.8125 14 8V22C14 24.25 15.75 26 18 26Z"/>
        </svg>);
}
const styles = css(iconStyles);
