import { css } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
export function CircleInfoIcon() {
    return (<svg className={styles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 36C14.25 36 9 33 6.125 28C3.25 23.0625 3.25 17 6.125 12C9 7.0625 14.25 4 20 4C25.6875 4 30.9375 7.0625 33.8125 12C36.6875 17 36.6875 23.0625 33.8125 28C30.9375 33 25.6875 36 20 36ZM17.5 25C16.625 25 16 25.6875 16 26.5C16 27.375 16.625 28 17.5 28H22.5C23.3125 28 24 27.375 24 26.5C24 25.6875 23.3125 25 22.5 25H22V19.5C22 18.6875 21.3125 18 20.5 18H17.5C16.625 18 16 18.6875 16 19.5C16 20.375 16.625 21 17.5 21H19V25H17.5ZM20 12C18.875 12 18 12.9375 18 14C18 15.125 18.875 16 20 16C21.0625 16 22 15.125 22 14C22 12.9375 21.0625 12 20 12Z"/>
        </svg>);
}
const styles = css(iconStyles, {
    width: 20,
    height: 20,
});
